import {FIPlayer} from '@my-game-plan/types';

import Avatar from '@mui/material/Avatar';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import React, {useEffect, useState} from 'react';
import Logo from '@/components/common/Logo/Logo';
import Stat from '@/components/common/stat/stat.view';
import {useTranslation} from 'react-i18next';
import {ILinkState} from '@/types/routes.types';
import {Link} from 'react-router-dom';
import {ROUTE, SCOUTING_ROUTES} from '@/config/routes.config';

interface IPlayerBioCardProps {
  player: FIPlayer;
  backLink?: ILinkState;
}

function PlayerBioCard(props: IPlayerBioCardProps): JSX.Element {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const [_age, _setAge] = useState<number>(0);

  /*
   * Side effects
   */
  useEffect(() => {
    const _calculatedAge =
      new Date().getFullYear() -
      new Date(props.player.birth_date).getFullYear();
    _setAge(_calculatedAge);
  }, [props.player]);

  /*
   * Handlers
   */

  /*
   * Render
   */
  return (
    <Card>
      <CardHeader
        avatar={
          <Avatar
            src={props.player.image_url}
            sx={{bgcolor: 'background.default', color: 'secondary.dark'}}
          />
        }
        title={props.player.name}
        subheader={
          props.player.teams?.[0] ? (
            <Stack direction="row" alignItems="center" gap={1}>
              <Logo src={props.player.teams?.[0]?.image_url} size="xsmall" />
              <Typography variant="body2" color="text.secondary">
                {props.player.teams?.[0]?.name}
              </Typography>
            </Stack>
          ) : (
            <Typography
              variant="body2"
              color="text.disabled"
              fontStyle="italic">
              {t('players.info.noClub')}
            </Typography>
          )
        }
      />
      <CardContent>
        <Grid container spacing={2}>
          {/* Position */}
          <Grid item xs={4}>
            <Stat
              label={t('players.info.position')}
              value={props.player.position.join(', ')}
              variant="secondary"
            />
          </Grid>

          {/* Country */}
          <Grid item xs={4}>
            <Stat
              label={t('players.info.country')}
              value={props.player.country}
              variant="secondary"
            />
          </Grid>

          {/* Age */}
          <Grid item xs={4}>
            <Stat
              label={t('players.info.age')}
              value={_age}
              variant="secondary"
            />
          </Grid>

          {/* Foot */}
          {props.player.preferred_foot && (
            <Grid item xs={4}>
              <Stat
                label={t('players.info.foot')}
                value={props.player.preferred_foot}
                variant="secondary"
              />
            </Grid>
          )}

          {/* Height */}
          {props.player.height && (
            <Grid item xs={4}>
              <Stat
                label={t('players.info.height')}
                value={props.player.height}
                valueSuffix={t('physical.units.cm')}
                variant="secondary"
              />
            </Grid>
          )}

          {/* Age */}
        </Grid>
      </CardContent>
      <CardActions sx={{justifyContent: 'flex-end'}}>
        <Button
          component={Link}
          to={`/${ROUTE.scouting}/${SCOUTING_ROUTES.player}/${props.player._id}`}
          state={props.backLink}
          size="small">
          {t('players.cta.viewPlayer')}
        </Button>
      </CardActions>
    </Card>
  );
}

export default PlayerBioCard;
