import {Resource} from '@/types/api.types';
import {MATCHES_API_CONFIG} from '@/config/api.config';
import {useAPI} from '@/hooks/api.hooks';
import {FIMatch} from '@my-game-plan/types';
import {REQUEST_ERRORS} from '@/config/errors.config';

const api = useAPI();

export const getMatchesByTeam = async (
  _team: string | undefined,
): Promise<FIMatch[]> => {
  try {
    const {data} = await api.get<FIMatch[]>({
      resource: Resource.matches,
      url: MATCHES_API_CONFIG.getMatchesByTeam(_team),
    });

    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }

    return data;
  } catch {
    return [];
  }
};
