import {FIBenchmarkTimeOptions} from '@my-game-plan/types';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import HeaderFilterButton, {
  IFilterButtonValueSubSegment,
} from '../header-filter-button.view';

import DateRangeIcon from '@mui/icons-material/DateRange';
import TimeFilterPopup from './time-filter-popup.view';
import {useAuth} from '@/context/auth.context';
import {timeRangeFiltersToText} from '@/helpers/event-automations.helper';
import MatchesFilterPopup, {
  IMatchFilterConfig,
} from '../matches-filter/matches-filter-popup.view';
import {useCompetitions} from '@/context/competitions.context';
import _ from 'lodash';

interface ITimeFilterButtonProps {
  value?: FIBenchmarkTimeOptions;
  onRangeChange: (options: FIBenchmarkTimeOptions) => void;
  matchFilterConfig?: IMatchFilterConfig;
  observingMatchesCount?: number;
}

function TimeFilterButton(props: ITimeFilterButtonProps): JSX.Element {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const _competitionsContext = useCompetitions();
  const _authContext = useAuth();
  const [_labelValues, _setLabelValues] = useState<
    IFilterButtonValueSubSegment[][]
  >([]);
  const [_anchorEl, _setAnchorEl] = useState<HTMLElement | null>(null);

  /*
   * Side effects
   */
  // Define filter button label
  useEffect(() => {
    const _generatedLabelValues: IFilterButtonValueSubSegment[][] = [];

    // "data" or "X matches"
    if (typeof props.observingMatchesCount === 'undefined') {
      _generatedLabelValues.push([
        {
          type: 'default',
          value: t('eventAutomations.filter.data'),
        },
      ]);
    } else {
      _generatedLabelValues.push([
        {
          type: 'value',
          value: props.observingMatchesCount.toString(),
        },
        {
          type: 'default',
          value: t('eventAutomations.filter.matches', {
            count: props.observingMatchesCount,
          }),
        },
      ]);
    }

    // "of" or "since"
    let _ofOrSince = t('eventAutomations.benchmark.of');
    if (props.value?.since_date) {
      _ofOrSince = t('eventAutomations.filter.time.since');
    }
    _generatedLabelValues.push([
      {
        type: 'default',
        value: _ofOrSince,
      },
    ]);

    const _seasonValueText = timeRangeFiltersToText(
      props.value,
      _authContext.user?.current_season_id,
      _competitionsContext.displayMultiYear,
    );
    _generatedLabelValues.push([
      {
        type: 'value',
        value: _seasonValueText.value,
      },
    ]);
    _setLabelValues(_generatedLabelValues);
  }, [
    props.value,
    _authContext.user?.current_season_id,
    props.observingMatchesCount,
    _competitionsContext.displayMultiYear,
  ]);

  /*
   * Handlers
   */
  function _onPopupOpen(event: React.MouseEvent<HTMLElement>) {
    _setAnchorEl(event.currentTarget);
  }
  function _onPopupClose() {
    _setAnchorEl(null);
  }

  /*
   * Render
   */

  let _PopupContent = (
    <TimeFilterPopup
      anchorEl={_anchorEl}
      onClose={_onPopupClose}
      value={props.value}
      onDateRangeChange={props.onRangeChange}
    />
  );

  if (props.matchFilterConfig) {
    _PopupContent = (
      <MatchesFilterPopup
        anchorEl={_anchorEl}
        onClose={_onPopupClose}
        value={props.value}
        onDateRangeChange={props.onRangeChange}
        matchFiltersConfig={props.matchFilterConfig}
      />
    );
  }

  return (
    <HeaderFilterButton
      buttonPrefix={t('eventAutomations.filter.showing')}
      ButtonIcon={DateRangeIcon}
      buttonValues={_labelValues}
      PopupContent={_PopupContent}
      onPopupOpen={_onPopupOpen}
    />
  );
}

export default TimeFilterButton;
