import {t} from 'i18next';
import React, {useEffect, useState} from 'react';

import styles from './TrackerDetailVideos.module.scss';

import {FIFormattedAutomation, FIMatchEvent} from '@my-game-plan/types';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

import ExpandMore from '@mui/icons-material/ExpandMore';
import {formatDate} from '@/helpers/general/general.helper';
import {TrackerDetailVideoIcons} from './item/icons/TrackerDetailVideoIcons';

import {PlayTrackerVideosButton} from '../../../trackers/buttons/play-videos-button/PlayTrackerVideosButton';
import Logo from '@/components/common/Logo/Logo';
import PlaylistItem from '@/components/video/playlist/playlistItem/PlaylistItem';
import CompetitionIcon from '@/components/common/competition-icon/competition-icon.view';
import {useAutomationDetail} from '@/context/event-automations/automation-detail.context';

interface TrackerDetailVideosProps {
  tracker: FIFormattedAutomation;
  events: FIMatchEvent[];
  observingMatchIds: string[];
  isOnlyShowingObjectiveEvents?: boolean;
}

export function TrackerDetailVideos({
  tracker,
  events,
}: TrackerDetailVideosProps): JSX.Element {
  /*
   * Hooks n State
   */
  const [_activeMatch, _setActiveMatch] = useState<string | null>(null);

  const _automationDetailContext = useAutomationDetail();

  const [_eventsPerMatch, _setEventsPerMatch] = useState<
    Record<string, FIMatchEvent[]>
  >({});

  useEffect(() => {
    // Define events
    const _groupedEvents: Record<string, FIMatchEvent[]> = {};
    _automationDetailContext.visibleEvents.forEach((event) => {
      if (!_groupedEvents[event.match._id]) {
        _groupedEvents[event.match._id] = [];
      }

      _groupedEvents[event.match._id].push(event);
    });

    _setEventsPerMatch(_groupedEvents);
  }, [_automationDetailContext.visibleEvents]);

  /*
   * Handlers
   */

  function _onAccordionChange(matchId: string) {
    const _matchIDToSet = matchId === _activeMatch ? null : matchId;
    _setActiveMatch(_matchIDToSet);
  }

  const handleEventClick = (event: FIMatchEvent) => {
    _automationDetailContext.openVideoPlayer(events, event);
  };

  /*
   * Render
   */
  if (_automationDetailContext.history.length === 0)
    return <p style={{padding: 24}}>{t('eventAutomations.noVideos')}</p>;

  const _isRelative =
    tracker.calculation === 'ratio' || tracker.calculation === 'rule';

  return (
    <div className={styles.container}>
      {_automationDetailContext.history.map((match, index) => {
        const _videoNotAvailable = !match.match.video;
        // console.log('Render videos for match', match.match._id);
        // console.log(_eventsPerMatch[match.match._id]);
        const _eventsForMatch = _eventsPerMatch[match.match._id] || [];

        return (
          <Accordion
            sx={{boxShadow: 0}}
            defaultExpanded={Boolean(
              index === 0 && !_videoNotAvailable && match.total_events,
            )}
            key={match.match._id}
            expanded={_activeMatch === match.match._id}
            TransitionProps={{unmountOnExit: true}}
            onChange={() => _onAccordionChange(match.match._id)}>
            <AccordionSummary
              expandIcon={<ExpandMore color="secondary" />}
              aria-label="Expand"
              aria-controls="-content"
              id="-header">
              <div
                className={styles.triggerContainer}
                style={{cursor: events.length > 0 ? 'pointer' : 'initial'}}>
                <div className={styles.info}>
                  <div className={styles.matchInfo}>
                    <Logo src={match.match.home_team.image_url || ''} />
                    <div>{`${match.match.home_team_end_score} - ${match.match.away_team_end_score}`}</div>
                    <Logo src={match.match.away_team.image_url || ''} />
                  </div>
                  <div className={styles.match}>
                    <div className={styles.matchDay}>
                      <p>
                        {t('eventAutomations.matchday', {
                          day: match.match.match_day,
                        })}
                      </p>
                      <CompetitionIcon
                        competition_type={match.match.competition_type}
                      />
                    </div>
                    <div className={styles.caption}>
                      {`${formatDate(match.match.date, 'L')} - ${
                        match.match.competition_name
                      }`}
                    </div>
                  </div>
                  <TrackerDetailVideoIcons
                    relative={_isRelative}
                    totalEvents={match.total_events || 0}
                    totalSuccessfulEvents={match.total_successful_events}
                    minutesPlayed={
                      tracker.observing_players?.players?.length
                        ? match.minutes_played
                        : undefined
                    }
                  />
                </div>
                {!_videoNotAvailable && events.length > 0 && (
                  <div className={styles.playVideosButton}>
                    <PlayTrackerVideosButton
                      id={tracker._id}
                      action={tracker.rule_condition?.action || tracker.action}
                      details={tracker.success_details || tracker.details}
                      relative={_isRelative}
                      amountOfVideos={match.total_events || 0}
                      metric={tracker.metric}
                      type="text"
                      // onClick={(e) => handleWatchAllClick(e)}
                      events={_eventsForMatch}
                    />
                  </div>
                )}
                {_videoNotAvailable && (
                  <div className={styles.notAvailable}>
                    {t('error-states.video-not-available.short')}
                  </div>
                )}
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div className={styles.collapsibleContainer}>
                {_eventsPerMatch[match.match._id]?.map((ev) => (
                  <PlaylistItem
                    playlistItem={ev}
                    key={ev._id}
                    playClip={handleEventClick}
                    isRatio={_isRelative}
                    observingMetric={tracker.metric}
                    match={match.match}
                  />
                ))}
              </div>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
}
