enum ANALYTICS_EVENT {
  /* Dashboards */
  VIEWED_DASHBOARD = 'Viewed Dashboard',

  /* Overview Dashboards */
  CREATED_OVERVIEW_DASHBOARD = 'Created Widget Dashboard',
  VIEWED_OVERVIEW_DASHBOARD = 'Viewed Widget Dashboard',

  /* Trackers */
  CREATED_TRACKER = 'Created Tracker',
  VIEWED_TRACKERS_OVERVIEW = 'Viewed Trackers Overview',
  VIEWED_TRACKERS_DETAIL = 'Viewed Tracker Detail',
  BENCHMARKED_TRACKER = 'Benchmarked Tracker',

  /* Opponent automations */
  VIEWED_OPPONENT_AUTOMATIONS = 'Viewed Opponent Automations',
  VIEWED_OPPONENT_AUTOMATION = 'Viewed Opponent Automation',
  CREATED_OPPONENT_AUTOMATION = 'Created Opponent Automation',

  /* Event Automations */
  AUTOMATIONS_OVERVIEW_FILTERED = 'Automations Overview - Filtered by tag',
  AUTOMATIONS_OVERVIEW_SWITCHED_VIEW = 'Automations Overview - Switched view',

  /* Single sends */
  VIEWED_SINGLE_SENDS = 'Viewed Single Sends',
  VIEWED_SINGLE_SEND = 'Viewed Single Send Playlist',
  CREATED_SINGLE_SEND_PLAYLIST = 'Created Single Sends Playlist',

  /* Videos */
  WATCHED_VIDEO = 'Watched video',
  WATCHED_VIDEO_TIME_ADDED = 'Watched video - Added time',
  VIDEO_EDIT_START = 'Edit Video - Started',
  VIDEO_EDIT_TIME_ADDED = 'Edit Video - Added time',
  VIDEO_EDIT_SAVED = 'Edit Video - Saved',
  VIDEO_LIMIT_REACHED_TEAM = 'Video limit reached',
  WATCHED_VIDEO_ERROR = 'Tried watching video - Failed',
  VIDEO_DOWNLOADED = 'Video Downloaded',
  SWITCHED_VIDEO_SOURCE = 'Switched video source',

  /* Shot Analysis */
  VIEWED_SHOT_ANALYSIS = 'Viewed Shot Analysis',
  VIEWED_SHOT_ANALYSIS_DETAILS = 'Shot Analysis - Viewed Details',
  FILTERED_SHOT_ANALYSIS_TABLE = 'Shot Analysis - Filtered Table',

  /* Player page */
  VIEWED_PLAYER_PAGE = 'Viewed Player Page',
  PLAYER_PAGE_SWITCHED_TAB = 'Player Page - Switched Tab',
  PLAYER_PAGE_MIRRORED_PERFORMANCE = 'Player Page - Mirrored Performance',

  /* Objective */
  CREATED_PLAYER_OBJECTIVE = 'Created Player Objective',
  VIEWED_PLAYER_OBJECTIVES = 'Viewed Player Objectives',
  VIEWED_PLAYER_OBJECTIVE = 'Viewed Player Objective',
  ADDED_PLAYER_OBJECTIVE_COMMENT = 'Added Player Objective Comment',

  /* Uploads */
  VIEWED_UPLOADS = 'Viewed Uploads',
  UPLOADED_FILE = 'Uploaded File',
  VIEWED_UPLOADED_FILE = 'Viewed Uploaded File Content',

  /* Filters x Benchmark - Various screens */
  CHANGED_OWN_DATA_FILTERS = 'Changed Own Data Filters',
  CHANGED_BENCHMARK_FILTERS = 'Changed Benchmark Filters',
}

export default ANALYTICS_EVENT;
