import React, {useState} from 'react';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import LoadingButton from '@mui/lab/LoadingButton';
import {useTranslation} from 'react-i18next';
import {FITeam} from '@my-game-plan/types';
import {LOADING_STATE} from '@/types/screen.types';
import {useAuth} from '@/context/auth.context';

import {useSnackbar} from 'notistack';
import {switchTeam} from '@/controllers/admin.controller';
import {useNavigate} from 'react-router-dom';
import TeamSearchInput from '@/components/filter/team-search-input.view';

interface ISwitchTeamDialogViewProps {
  open: boolean;
  available_teams: FITeam[];
  onClose: () => void;
}
function SwitchTeamDialog(props: ISwitchTeamDialogViewProps): JSX.Element {
  /*
   * Hooks n State
   */
  const _authContext = useAuth();
  const _snackbar = useSnackbar();
  const _navigate = useNavigate();
  const {t} = useTranslation();
  const [_submitLoadingState, _setSubmitLoadingState] = useState<LOADING_STATE>(
    LOADING_STATE.INITING,
  );
  const [_selectedTeam, _setSelectedTeam] = useState<FITeam | null>(null);

  /*
   * Side effects
   */

  /*
   * Handlers
   */

  function _onTeamSelect(value: FITeam | null) {
    _setSelectedTeam(value);
  }

  async function _onSubmit() {
    if (!_authContext.user || !_selectedTeam) {
      return;
    }

    try {
      _setSubmitLoadingState(LOADING_STATE.LOADING);
      await switchTeam({
        user_id: _authContext.user._id,
        team_id: _selectedTeam._id,
      });

      _navigate(0);
    } catch (error) {
      _snackbar.enqueueSnackbar(t('error-states.default'));
    } finally {
      _setSubmitLoadingState(LOADING_STATE.SUCCESS);
      props.onClose();
    }
  }

  /*
   * Render
   */
  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>{t('preferences.switchTeam.title')}</DialogTitle>
      <DialogContent>
        <Stack gap={2}>
          <Typography color="text.secondary">
            {t('preferences.switchTeam.text')}
          </Typography>

          <TeamSearchInput
            selectedTeam={_selectedTeam}
            onTeamSelect={_onTeamSelect}
            availableTeams={props.available_teams}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          disabled={!_selectedTeam}
          onClick={_onSubmit}
          variant="contained"
          loading={_submitLoadingState === LOADING_STATE.LOADING}>
          {t('preferences.switchTeam.submit')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

export default SwitchTeamDialog;
