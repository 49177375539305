import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import Fab from '@mui/material/Fab';
import Screen from '../screen/screen.view';
import Stack from '@mui/material/Stack';
import {LOADING_STATE, TOverviewScreenViewType} from '@/types/screen.types';
import {useTranslation} from 'react-i18next';
import AutomationCreateButton from '../automations/automation-create-button/automation-create-button.view';
import {
  FIAutomationDataQueryParams,
  FIEventFilters,
  FIFormattedAutomation,
  FIPlayerWithPerformance,
  TEventAutomationType,
} from '@my-game-plan/types';

import {
  IAutomationFilters,
  TFilterOptions,
} from '@/types/event-automations-misc.types';
import CustomEmptyState from '../error-states/CustomEmptyState';
import OverviewTableScreen from './table/overview-table-screen.view';
import PerformanceCardsOverview from './cards/performance-cards-overview.view';
import ViewToggle from './view-toggle/view-toggle.view';
import {
  OverviewScreenProvider,
  useOverviewScreen,
} from '@/context/overview-screen-context';
import HeaderFilterBar from '../filter/header-filter-bar/header-filter-bar.view';

const LOADING_STATES_SHOWING_DATA: LOADING_STATE[] = [
  LOADING_STATE.LOADING,
  LOADING_STATE.SUCCESS,
];
export interface IOverviewScreenView<
  T = FIPlayerWithPerformance | FIFormattedAutomation,
> {
  loadingState: LOADING_STATE;
  type: TOverviewScreenViewType;
  data: T[];
  error?: string;
  availableFilterOptions: TFilterOptions;
  getData: (
    queryParams?: FIAutomationDataQueryParams,
    optionalEventFilters?: FIEventFilters,
  ) => void;
}
interface IOverviewScreenProps {
  title: string;
  automationType: TEventAutomationType;
  views: IOverviewScreenView[];
  filters: FIAutomationDataQueryParams | null;
  onFiltersChange: (filters: FIAutomationDataQueryParams | null) => void;
  canSelectPlayers?: boolean;
  canSelectOpponents?: boolean;
}

function OverviewScreenWithoutContext(
  props: IOverviewScreenProps,
): JSX.Element {
  /*
   * Hooks n State
   */

  const _overviewScreenContext = useOverviewScreen();
  const {t} = useTranslation();

  const NAMESPACE =
    _overviewScreenContext.automationType === 'opponent-automation'
      ? 'opponentAutomations'
      : 'trackers';

  /*
   * Handlers
   */

  /*
   * Render
   */

  let _Content = null;

  const _filters: IAutomationFilters = {
    players: _overviewScreenContext.selectedPlayerIds,
  };

  if (
    _overviewScreenContext.currentView?.loadingState === LOADING_STATE.ERROR
  ) {
    _Content = (
      <CustomEmptyState
        imageType="error"
        header={t('error-states.not-found.header')}
        description={t('error-states.not-found.description')}
      />
    );
  } else if (
    _overviewScreenContext.currentView?.loadingState ===
      LOADING_STATE.SUCCESS &&
    !_overviewScreenContext.currentView.data.length
  ) {
    _Content = (
      <CustomEmptyState
        header={t(`eventAutomations.empty.${NAMESPACE}.header`)}
        description={t(`eventAutomations.empty.${NAMESPACE}.description`)}
      />
    );
  } else if (
    _overviewScreenContext.currentView &&
    LOADING_STATES_SHOWING_DATA.includes(
      _overviewScreenContext.currentView?.loadingState,
    ) &&
    _overviewScreenContext.currentView.type === 'table'
  ) {
    _Content = (
      <OverviewTableScreen
        title=""
        data={
          _overviewScreenContext.currentView.data as FIFormattedAutomation[]
        }
        automationType={_overviewScreenContext.automationType}
        // keyExtractor={_keyExtractor}
        isLoading={false}
        isInitialised={true}
        error={false}
        selectedFilters={_filters}
      />
    );
  } else if (
    _overviewScreenContext.currentView &&
    LOADING_STATES_SHOWING_DATA.includes(
      _overviewScreenContext.currentView?.loadingState,
    ) &&
    _overviewScreenContext.currentView.type === 'cards'
  ) {
    _Content = (
      <PerformanceCardsOverview
        selectedFilters={_filters}
        data={
          _overviewScreenContext.currentView.data as FIPlayerWithPerformance[]
        }
      />
    );
  }

  // Render main content based on current view state
  // Create button
  const _createButtonTitle = t(
    `eventAutomations.${
      _overviewScreenContext.automationType === 'opponent-automation'
        ? 'automations'
        : 'trackers'
    }New`,
  );

  let _HeaderRightContent = null;
  if (_overviewScreenContext.views.length > 1) {
    _HeaderRightContent = (
      <ViewToggle
        views={_overviewScreenContext.views.map((view) => view.type)}
        activeViewType={_overviewScreenContext.currentViewType}
        onViewChange={_overviewScreenContext.onCurrentViewChange}
      />
    );
  }

  const _title =
    _overviewScreenContext.automationType === 'opponent-automation'
      ? t('eventAutomations.opponentAutomations', {count: 0})
      : t('team.performance');
  return (
    <Screen
      loadingState={
        _overviewScreenContext.currentView?.loadingState ||
        LOADING_STATE.INITING
      }
      title={_title}
      // displayHeaderBorder
      coloredHeader
      HeaderRightContent={_HeaderRightContent}>
      <Stack gap={4}>
        <HeaderFilterBar
          timeFilters={{
            filters:
              _overviewScreenContext.timeAndBenchmarkFilters?.own_data?.time,
            onFiltersChange: _overviewScreenContext.timeFilterChangeHandler,
          }}
          benchmark={{
            benchmarkOptions:
              _overviewScreenContext.timeAndBenchmarkFilters?.benchmark,
            onBenchmark: _overviewScreenContext.benchmarkHandler,
          }}
          playerFilter={
            props.canSelectPlayers
              ? {
                  selectedPlayerIds: _overviewScreenContext.selectedPlayerIds,
                  onPlayerSelect: _overviewScreenContext.onPlayerSelect,
                }
              : undefined
          }
          opponentFilter={
            props.canSelectOpponents
              ? {
                  onOpponentSelect: _overviewScreenContext.onOpponentSelect,
                  selectedOpponentId: _overviewScreenContext.selectedOpponent,
                }
              : undefined
          }
          screen={`overview-${_overviewScreenContext.automationType}`}
        />
        <Stack position="relative">
          {_Content}
          <AutomationCreateButton
            type={_overviewScreenContext.automationType}
            Button={
              <Fab
                color="primary"
                aria-label="add"
                sx={{position: 'fixed', bottom: '1rem', right: '1rem'}}
                title={_createButtonTitle}>
                <AddIcon />
              </Fab>
            }
          />
        </Stack>
      </Stack>
    </Screen>
  );
}

function OverviewScreen(props: IOverviewScreenProps): JSX.Element {
  return (
    <OverviewScreenProvider {...props}>
      <OverviewScreenWithoutContext {...props} />
    </OverviewScreenProvider>
  );
}

export default OverviewScreen;
