import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {
  ACTION,
  IActionConfigDetails,
  FIConditionalEventFilter,
  FIConditionalEventFilters,
  FIEventAutomationPostData,
  TQueryOperatorType,
  FIEventFilters,
  IEventFilters,
  generateActionConfigs,
} from '@my-game-plan/types';
import cn from 'classnames';
import {translateAction} from '@/helpers/translation.helper';

import {ICommonSentenceInputProps} from '@/types/sentence-form.types';
import AddFilterButton from './add-filter-button/add-filter-button.view';

import FilterValuesSelect from './filter-values-select/filter-values-select.view';
import Segment from '../..';
import {OPERATOR_OPTIONS} from '@/config/event-automation-builder.config';

import dropdownStyles from '../dropdown.module.scss';
import styles from './details-dropdown.module.scss';
import {useTeams} from '@/context/team.context';
import {generateGeneralMatchDetails} from '@/helpers/automation/automation-form.helper';
import {ITypedOption} from '@/types/option.types';
import {useZones} from '@/context/zones.context';

interface IDetailsDropdownContent<DataType>
  extends ICommonSentenceInputProps<FIConditionalEventFilters, DataType> {
  action: ACTION;
  onNestedPopupToggle: (hasOpenPopup: boolean) => void;
  isGameStateCondition?: boolean;
  transferredPlayers: ITypedOption<string>[];
}

function DetailsDropdownContent<DataType = FIEventAutomationPostData>(
  props: IDetailsDropdownContent<DataType>,
): JSX.Element {
  /* Hooks n State */
  const {t} = useTranslation();
  const [_possibleDetails, _setPossibleDetails] =
    useState<IActionConfigDetails>({});
  const _teamsContext = useTeams();
  const _zonesContext = useZones();

  useEffect(() => {
    const _actionConfigs = generateActionConfigs(
      _teamsContext.ownTeam?.data_provider,
    );

    const _actionConfig = _actionConfigs[props.action];

    if (
      _actionConfig &&
      !props.isGameStateCondition &&
      _actionConfig.possibleDetails
    ) {
      // Manually override details for coordinates and received by
      Object.keys(_actionConfig.possibleDetails).forEach((key) => {
        const _key = key as keyof IEventFilters;
        if (_actionConfig.possibleDetails && _key.includes('coordinates')) {
          _actionConfig.possibleDetails[_key] = _zonesContext.all.map(
            (z) => z.name,
          );
        }
      });
      _setPossibleDetails(_actionConfig.possibleDetails);
    } else if (props.isGameStateCondition && _teamsContext.ownTeamFormations) {
      const _generatedFilters = generateGeneralMatchDetails(
        _teamsContext.ownTeamFormations,
      );
      _setPossibleDetails(_generatedFilters);
    }
  }, [
    _teamsContext.ownTeamFormations,
    _zonesContext.all,
    _teamsContext.ownTeam,
  ]);

  /* Handlers */
  function _updateFilters(filtersToSave: FIConditionalEventFilters) {
    if (props.onChange) {
      props.onChange({
        [props.property as keyof DataType]: filtersToSave,
      } as unknown as Partial<DataType>);
    }
  }
  function _onAddFilterClick(field: keyof FIEventFilters) {
    const _emptyFilter: FIConditionalEventFilter = {
      property: field,
      values: [],
      operator: 'or',
    };

    const _defaultOpperator: TQueryOperatorType = field.includes('coordinates')
      ? 'or'
      : 'and';
    const _newFilters: FIConditionalEventFilters = {
      operator: props.value?.operator || _defaultOpperator,
      filters: [...(props.value ? props.value.filters : []), _emptyFilter],
    };

    _updateFilters(_newFilters);
  }

  function _onDeleteFilter(field: keyof FIEventFilters) {
    if (props.value) {
      const _index = props.value.filters.findIndex(
        (filter) => filter.property === field,
      );

      if (_index > -1) {
        const _newFilters: FIConditionalEventFilters = {
          ...props.value,
        };
        _newFilters.filters.splice(_index, 1);

        _updateFilters(_newFilters);
      }
    }
  }

  function _onFilterChange(filter: FIConditionalEventFilter) {
    if (props.value) {
      const _filterIndex = props.value.filters.findIndex(
        (propFilter) => propFilter.property === filter.property,
      );
      if (_filterIndex > -1) {
        const _newFilters: FIConditionalEventFilters = {
          ...props.value,
        };
        _newFilters.filters.splice(_filterIndex, 1, filter);

        _updateFilters(_newFilters);
      }
    }
  }

  function _onOperatorChange(value: TQueryOperatorType) {
    if (props.value) {
      const _newFilters: FIConditionalEventFilters = {
        ...props.value,
        operator: value,
      };

      _updateFilters(_newFilters);
    }
  }

  /* Render */
  const _filtersCount = props.value ? props.value.filters.length : 0;
  const _hasFilters = props.value && _filtersCount > 0;
  const _hasMultipleFilters = props.value && _filtersCount > 1;

  const _titleActionLabel = translateAction(props.action);
  const _title = t('sentenceForm.details.title', {action: _titleActionLabel});
  const _operatorLabel = t(`sentenceForm.${props.value?.operator}`);

  return (
    <div className={styles.dropdownContent}>
      <div className={dropdownStyles.title}>{_title}</div>
      <div className={styles.selectedFilters}>
        {_hasFilters &&
          props.value?.filters.map((filter, index) => {
            let _AndOrLine = null;
            if (_hasMultipleFilters && index === 0) {
              _AndOrLine = (
                <div className={cn(styles.andOr, styles.andOrInput)}>
                  <Segment.Dropdown
                    options={OPERATOR_OPTIONS}
                    onChange={_onOperatorChange}
                    data={props.data}
                    size="small"
                    value={props.value?.operator}
                  />
                </div>
              );
            } else if (_hasMultipleFilters && index < _filtersCount - 1) {
              _AndOrLine = (
                <div className={cn(styles.andOr, styles.andOrText)}>
                  {_operatorLabel}
                </div>
              );
            }

            return (
              <div className={styles.line} key={filter.property}>
                <FilterValuesSelect
                  possibleDetails={_possibleDetails}
                  filter={filter}
                  onChange={_onFilterChange}
                  onDelete={_onDeleteFilter}
                  onPopupToggle={props.onNestedPopupToggle}
                  isGameStateCondition={props.isGameStateCondition}
                  transferredPlayers={props.transferredPlayers}
                  action={props.action}
                  // isObservingOpponent={
                  //   props.observingTeamId !== _teamsContextContext.ownTeam?._id
                  // }
                />
                {_AndOrLine}
              </div>
            );
          })}
      </div>

      <div className={styles.addButton}>
        <AddFilterButton
          onChange={_onAddFilterClick}
          action={props.action}
          possibleDetails={_possibleDetails}
          selectedFilters={props.value?.filters}
          onPopupToggle={props.onNestedPopupToggle}
          isGameStateCondition={props.isGameStateCondition}
        />
      </div>
    </div>
  );
}

export default DetailsDropdownContent;
