import React, {ReactNode, useEffect, useState} from 'react';

import styles from './overview-card.module.scss';
import {
  FISingleSend,
  FITag,
  TEventAutomationType,
  FIFormattedAutomation,
} from '@my-game-plan/types';
import Card from '@mui/material/Card';
import FormLabel from '@mui/material/FormLabel';
import Stack from '@mui/material/Stack';

import {CardHeader} from './card-header/CardHeader';
import {formattedAutomationToPostData} from '@/helpers/event-automations.helper';
import InlineFiltersSentence from '@/components/automations/sentence/builder-sentence.view';
import OverviewCardField, {
  IOverviewCardField,
} from './overview-card-field/overview-card-field.view';
import Typography from '@mui/material/Typography';

import {useTeams} from '@/context/team.context';
import {hashtagify} from '@/helpers/translation.helper';
import {deepCopy} from '@/helpers/general/general.helper';

interface IOverviewCardProps {
  children?: ReactNode;
  data: FIFormattedAutomation | FISingleSend;
  fields: IOverviewCardField[][];
  automationType?: TEventAutomationType;
  bottomContent?: ReactNode;
  bottomContentLabel?: string;
  primaryAction?: ReactNode;
  secondaryAction?: ReactNode;
}

function OverviewCard(props: IOverviewCardProps): JSX.Element {
  /*
   * Hooks n State
   */
  const [_title, _setTitle] = useState<string | ReactNode | null>(null);
  const [_tags, _setTags] = useState<FITag[]>([]);
  const _teamsContext = useTeams();

  useEffect(() => {
    const _filteredTags: FITag[] = [];
    if ('tags' in props.data && props.data.tags?.length) {
      props.data.tags.forEach((t) => {
        const foundTag = (
          deepCopy(_teamsContext.ownTeam?.preferences?.tags) as FITag[]
        )?.find((tag) => tag._id == t);
        if (foundTag) {
          foundTag.label = `#${hashtagify(foundTag.label, false)}`;

          _filteredTags.push(foundTag);
        }
      });

      _setTags(_filteredTags.sort());
    }
  }, [props.data, _teamsContext.ownTeam]);

  // TODO a bit of code duplication from overview-screen.view.tsx :(
  useEffect(() => {
    if ('title' in props.data) {
      _setTitle(props.data.title);
      return;
    } else {
      const _formattedData = formattedAutomationToPostData(props.data);

      const _TitleNode = (
        <InlineFiltersSentence
          automationType={props.automationType}
          size="small"
          data={_formattedData}
          readonly
          withTarget={false} //{Boolean(typeof props.data.target !== 'undefined')}
        />
      );
      _setTitle(_TitleNode);
    }
  }, [props.data]);

  /*
   * Handlers
   */

  /*
   * Render
   */
  // let _title: string | ReactNode | null = null;

  return (
    <Card className={styles.card} sx={{display: 'flex'}}>
      <CardHeader createdAt={props.data.created_at} title={_title} />
      {Boolean(_tags?.length) && (
        <Stack direction="row" spacing={1} pt={1}>
          {_tags?.map((tag) => (
            <Typography color="secondary" fontSize={14} key={tag?._id}>
              {tag.label}
            </Typography>
          ))}
        </Stack>
      )}

      <Stack className={styles.fields} spacing={3} sx={{flex: 1}}>
        {props.fields.map((row, index) => (
          <Stack direction="row" key={index} spacing={1}>
            {row.map((field) => (
              <OverviewCardField key={field.label} data={field} />
            ))}
          </Stack>
        ))}
      </Stack>
      {props.bottomContent && (
        <div className={styles.bottomContent}>
          {props.bottomContentLabel && (
            <FormLabel
              color="secondary"
              sx={{
                textTransform: 'uppercase',
                fontSize: 10,
                fontWeight: '600',
                marginBottom: 1,
                display: 'block',
                opacity: 0.6,
              }}>
              {props.bottomContentLabel}
            </FormLabel>
          )}
          {props.bottomContent}
        </div>
      )}
      {(props.primaryAction || props.secondaryAction) && (
        <div className={styles.actions}>
          {props.primaryAction}
          {props.secondaryAction && (
            <div className={styles.actionSecondary}>
              {props.secondaryAction}
            </div>
          )}
        </div>
      )}
    </Card>
  );
}

export default OverviewCard;
