import {KonvaEventObject} from 'konva/lib/Node';
import React, {useRef, useState} from 'react';
import {Circle, Group, Layer, Line, Rect, Stage} from 'react-konva';

import {DOT_SIZE} from '@/config/pitch-visual.config';

import {
  Dimensions,
  IPitchVisualEventGroup,
  XYPos,
} from '@/types/dashboard/dashboard-pitch-visual.types';
import {
  ACTION,
  FICompactMatchInfo,
  FIConditionalEventFilters,
  FIEventFilters,
  FIMatch,
  FIMatchEvent,
  FIPitchZone,
  PitchVisualFocusType,
  PitchVisualType,
} from '@my-game-plan/types';

import Popover from '@mui/material/Popover';
import EventPopup from '../event-popup/EventPopup';
import FocusDropdown from './focus-dropdown/FocusDropdown';
import styles from './pitch-visual.module.scss';

import {
  calculateFinishingPosition,
  calculatePosition,
  calculateZoneDimensions,
} from '@/helpers/pitch-visual.helper';
import {useVideo} from '@/context/video/video.context';
import {useTheme} from '@mui/material';
import Konva from 'konva';

interface ScatterPlotProps {
  dimensions: Dimensions;
  eventGroups: IPitchVisualEventGroup[];
  type: PitchVisualType;
  showPath: boolean;
  defaultFocusType: PitchVisualFocusType;
  isHalf?: boolean;
  defending: boolean;
  isGoal?: boolean;
  action: ACTION;
  details?: FIConditionalEventFilters;
  metric?: keyof FIEventFilters;
  zones?: FIPitchZone[];
  shouldDisplayOnOwnHalf?: boolean;
  shouldDifferentiateSuccessfulEvents?: boolean;
  matches?: FIMatch[] | FICompactMatchInfo[];
}

interface HoveredEvent {
  event: FIMatchEvent;
  coordinates: XYPos;
}

function ScatterPlot(props: ScatterPlotProps): JSX.Element {
  /* Hooks n State */
  const _video = useVideo();
  const _theme = useTheme();
  const _containerRef = useRef<HTMLDivElement>(null);

  const [_hoveredEvent, _setHoveredEvent] = useState<HoveredEvent | null>(null);

  const [_activeFocusType, _setActiveFocusType] =
    useState<PitchVisualFocusType>(props.defaultFocusType);

  /* Handlers */
  function _onMouseEnter(e: KonvaEventObject<MouseEvent>, event: FIMatchEvent) {
    const container = e.target.getStage()?.container();
    if (container) container.style.cursor = 'pointer';

    _setHoveredEvent({
      event,
      coordinates: {
        x: e.evt.clientX,
        y: e.evt.clientY,
      },
    });
  }

  function _onMouseLeave(e: KonvaEventObject<MouseEvent>) {
    const container = e.target.getStage()?.container();
    if (container) container.style.cursor = 'default';
    _setHoveredEvent(null);
  }

  function _onMouseDown(event: FIMatchEvent) {
    const _matches =
      props.matches?.filter((match) => match._id === event.match._id) || [];
    _video.openVideoPlayer([event], _matches, undefined, true, {
      action: props.action,
      details: props.details,
      metric: props.metric,
    });
  }

  function _onDropdownChange(type: PitchVisualFocusType) {
    _setActiveFocusType(type);
  }

  /* Render */
  const _shouldShowStart =
    _activeFocusType === PitchVisualFocusType.START_END ||
    _activeFocusType === PitchVisualFocusType.START_FOCUS ||
    _activeFocusType === PitchVisualFocusType.START;

  const _shouldShowLine =
    props.showPath &&
    (_activeFocusType === PitchVisualFocusType.START_END ||
      _activeFocusType === PitchVisualFocusType.START_FOCUS ||
      _activeFocusType === PitchVisualFocusType.END_FOCUS);

  const _shouldShowEnd =
    _activeFocusType === PitchVisualFocusType.START_END ||
    _activeFocusType === PitchVisualFocusType.END_FOCUS ||
    _activeFocusType === PitchVisualFocusType.END;

  const _positionCalculator = props.isGoal
    ? calculateFinishingPosition
    : calculatePosition;

  return (
    <div className={styles.pitchOverlay}>
      {props.dimensions && (
        // Canva
        <div className={styles.canvasWrapper} ref={_containerRef}>
          <Stage
            height={props.dimensions.height}
            width={props.dimensions.width}>
            {/* Zones */}
            <Layer>
              {props.zones?.map((zone) => {
                const _rect = calculateZoneDimensions(
                  zone.box,
                  props.dimensions,
                  props.defending,
                );
                return (
                  <Rect
                    key={zone._id}
                    x={_rect.x}
                    y={_rect.y}
                    width={_rect.width}
                    height={_rect.height}
                    fill={`${_theme.palette.primary.main}1A`}
                    dash={[5, 5]}
                    stroke={_theme.palette.primary.main}
                    opacity={0.8}
                    strokeWidth={1}
                  />
                );
              })}
            </Layer>

            {/* Events */}
            <Layer>
              {props.eventGroups.map((eventGroup) =>
                eventGroup.events.map((event) => {
                  const {startX, endX, startY, endY} = _positionCalculator(
                    event,
                    props.dimensions,
                    props.isHalf,
                    props.defending,
                    props.shouldDisplayOnOwnHalf,
                  );

                  const _hasEndCoordinates = endY !== null && endX !== null;
                  let _groupColor = eventGroup.color;
                  let _isFaded = false;
                  let _fillStyle: Konva.NodeConfig = {
                    fill: eventGroup.color,
                  };

                  if (
                    props.shouldDifferentiateSuccessfulEvents &&
                    !event.successful
                  ) {
                    _groupColor = `${eventGroup.color}57`;
                    _isFaded = true;
                    const STROKE_WIDTH = 1;
                    const SIZE = DOT_SIZE - STROKE_WIDTH;
                    _fillStyle = {
                      fill: `${_theme.palette.background.paper}`,
                      strokeWidth: STROKE_WIDTH,
                      stroke: _groupColor,
                      // fill: eventGroup.color,
                      // opacity: 0.1,
                      height: SIZE,
                      width: SIZE,
                    };
                  }

                  return (
                    <Group key={event._id}>
                      {_shouldShowStart && props.showPath && (
                        <Rect
                          width={DOT_SIZE}
                          height={DOT_SIZE}
                          x={startX - DOT_SIZE / 2}
                          y={startY - DOT_SIZE / 2}
                          onMouseEnter={(e) => _onMouseEnter(e, event)}
                          onMouseDown={() => _onMouseDown(event)}
                          onMouseLeave={(e) => _onMouseLeave(e)}
                          {..._fillStyle}
                        />
                      )}
                      {_shouldShowStart && !props.showPath && (
                        <Circle
                          width={DOT_SIZE}
                          height={DOT_SIZE}
                          x={startX}
                          y={startY}
                          onMouseEnter={(e) => _onMouseEnter(e, event)}
                          onMouseDown={() => _onMouseDown(event)}
                          onMouseLeave={(e) => _onMouseLeave(e)}
                          {..._fillStyle}
                        />
                      )}
                      {_shouldShowLine && _hasEndCoordinates && (
                        <Line
                          strokeWidth={1}
                          stroke={_groupColor}
                          dash={_isFaded ? [5, 10] : undefined}
                          points={[startX, startY, endX, endY]}
                        />
                      )}
                      {_shouldShowEnd && _hasEndCoordinates && (
                        <Circle
                          width={DOT_SIZE}
                          height={DOT_SIZE}
                          x={endX}
                          y={endY}
                          onMouseEnter={(e) => _onMouseEnter(e, event)}
                          onMouseDown={() => _onMouseDown(event)}
                          onMouseLeave={(e) => _onMouseLeave(e)}
                          {..._fillStyle}
                        />
                      )}
                    </Group>
                  );
                }),
              )}
            </Layer>
          </Stage>

          {/* End/Start Dropdown */}
          {props.showPath && (
            <div className={styles.dropdown}>
              <FocusDropdown
                visual="scatter"
                value={_activeFocusType}
                onChange={_onDropdownChange}
              />
            </div>
          )}

          {/* Event popup */}
          <Popover
            sx={{pointerEvents: 'none'}}
            PaperProps={{
              sx: {
                bgcolor: 'background.default',
              },
            }}
            anchorEl={_containerRef.current}
            open={Boolean(_hoveredEvent)}
            anchorReference="anchorPosition"
            anchorPosition={{
              left: _hoveredEvent?.coordinates.x || 0,
              top: _hoveredEvent?.coordinates.y || 0,
            }}>
            {_hoveredEvent && (
              <EventPopup event={_hoveredEvent.event} metric={props.metric} />
            )}
          </Popover>
        </div>
      )}
    </div>
  );
}

export default ScatterPlot;
