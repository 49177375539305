import React from 'react';

import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import GridViewIcon from '@mui/icons-material/GridView';
import ViewListIcon from '@mui/icons-material/ViewList';

import {TOverviewScreenViewType} from '@/types/screen.types';
import {useTranslation} from 'react-i18next';

interface IViewToggleProps {
  activeViewType?: TOverviewScreenViewType;
  views: TOverviewScreenViewType[];
  onViewChange: (viewType: TOverviewScreenViewType) => void;
}

const IconMap: Record<TOverviewScreenViewType, JSX.Element> = {
  cards: <GridViewIcon />,
  table: <ViewListIcon />,
};

function ViewToggle(props: IViewToggleProps): JSX.Element {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();

  /*
   * Handlers
   */

  /*
   * Render
   */

  return (
    <ToggleButtonGroup
      value={props.activeViewType}
      exclusive
      onChange={(_, viewType) => props.onViewChange(viewType)}>
      {props.views.map((viewType) => (
        <ToggleButton
          key={viewType}
          value={viewType}
          size="small"
          title={t(`eventAutomations.views.${viewType}`)}>
          {IconMap[viewType]}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
}

export default ViewToggle;
