import React, {PropsWithChildren, useEffect, useState} from 'react';

import {
  createContextHook,
  createCustomContext,
  createProvider,
} from '@/helpers/general/context_generators.helper';
import {useAuth} from '@/context/auth.context';
import {
  deleteTag,
  editTag,
  getTagCount,
  getTeam,
  getTeamFormations,
  getTeams,
} from '@/controllers/teams.controller';
import {
  getPlayerCountries,
  getPlayersByTeam,
} from '@/controllers/players.controller';
import {DELETE_MODAL_CONFIG} from '@/config/modal.config';
import mixpanel from 'mixpanel-browser';
import {
  CountTagUsageBody,
  F,
  FIPlayer,
  FITeam,
  FITag,
  ITeamFormations,
} from '@my-game-plan/types';
import {useSnackbar} from 'notistack';
import {useTranslation} from 'react-i18next';
import {useConfirm} from 'material-ui-confirm';
import {ITypedOption} from '@/types/option.types';

export interface TeamsAPI {
  all: FITeam[];
  current: FITeam | null;
  getAll: (userId: string | undefined) => void;
  setCurrent: (team: string) => void;
  leagueTeams: FITeam[];
  players: FIPlayer[];
  ownTeam: FITeam | null;
  ownPlayers: FIPlayer[];
  getPlayers: () => void;
  getOwnPlayers: () => void;
  formations: ITeamFormations | null;
  ownTeamFormations: ITeamFormations | null;
  getFormations: () => void;
  getPlayerById: (_id: string) => FIPlayer | undefined;
  clear: () => void;
  nextOpponentId: string;
  opponentsOptions: ITypedOption<string>[];
  setOpponentsOptions: (options: ITypedOption<string>[]) => void;
  getOwnTeam: () => void;
  setOwnTeam: (team: FITeam) => void;
  getTagCountData: () => Promise<F<CountTagUsageBody> | undefined>;
  deleteTag: (tagToDelete: FITag) => void;
  editTag: (old_tag: string, new_tag: string) => void;

  allPlayerCountries: string[];
  setLeagueTeams: (teamIds: string[]) => void;
}

const context = createCustomContext<TeamsAPI>();
export const useTeams = createContextHook(context);

export const TeamsProvider = (
  props: PropsWithChildren<React.ReactNode>,
): JSX.Element => {
  const [_teams, _setTeams] = useState<FITeam[]>([]);
  const [_leagueTeams, _setLeagueTeams] = useState<FITeam[]>([]);
  const [_currentTeam, _setCurrentTeam] = useState<FITeam | null>(null);
  const [_ownTeam, _setOwnTeam] = useState<FITeam | null>(null);
  const [_players, _setPlayers] = useState<FIPlayer[]>([]);
  const [_ownPlayers, _setOwnPlayers] = useState<FIPlayer[]>([]);
  const [_formations, _setFormations] = useState<ITeamFormations | null>(null);
  const [_ownTeamFormations, _setOwnTeamFormations] =
    useState<ITeamFormations | null>(null);
  const [_nextOpponentId, _setNextOpponentId] = useState<string>('');
  const [_opponentsOptions, _setOpponentsOptions] = useState<
    ITypedOption<string>[]
  >([]);

  const [_allPlayerCountries, _setAllPlayerCountries] = useState<string[]>([]);
  const {user} = useAuth();
  const _snackbar = useSnackbar();
  const _confirm = useConfirm();
  const {t} = useTranslation();

  useEffect(() => {
    async function _fetchCountries() {
      const _fetchedCountries = await getPlayerCountries();
      _setAllPlayerCountries(_fetchedCountries);
    }

    if (user && _teams.length) {
      const _userTeam = _teams.find((team) => team._id === user.team);

      _setOwnTeam(_userTeam || null);

      if (_userTeam) {
        _getOwnFormations(_userTeam._id);
        mixpanel.people.set('team_name', _userTeam.name);
      }

      // Fetch player countries
      _fetchCountries();
    }
  }, [user, _teams]);

  /* Handlers */
  async function _getTeams(userID: string | undefined) {
    const _teamsData = await getTeams(userID);
    _setTeams(_teamsData);
    const _nextOpponent = _teamsData.find((team) => team.is_next_opponent);
    if (_nextOpponent) _setNextOpponentId(_nextOpponent._id);

    return _teamsData;
  }

  async function _getOwnTeam() {
    if (user) {
      const _team = await getTeam(user.team);
      _setOwnTeam(_team);
    }
  }

  async function _getFormations() {
    _setFormations(null);

    const _formationsData = await getTeamFormations(_currentTeam?._id);
    _setFormations(_formationsData);
  }

  async function _getOwnFormations(teamID: string) {
    _setOwnTeamFormations(null);

    const _formationsData = await getTeamFormations(teamID);
    _setOwnTeamFormations(_formationsData);
  }

  async function _setCurrent(name: string) {
    const _matchingTeam = _teams.find((team) => team._id === name);

    _setCurrentTeam(_matchingTeam || null);
  }

  async function _getPlayers() {
    _setPlayers([]);
    const _playersData = await getPlayersByTeam(_currentTeam?._id);
    _setPlayers(_playersData);
  }

  async function _getOwnPlayers() {
    const _playersData = await getPlayersByTeam(user?.team);
    _setOwnPlayers(_playersData);
  }

  function _getPlayerById(_id: string) {
    return _players.find((player) => player._id === _id);
  }

  async function getTagCountData() {
    try {
      if (!_ownTeam) return;
      const newTagCountData = await getTagCount(_ownTeam._id);
      if (!newTagCountData) return;
      return newTagCountData;
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  async function _deleteTag(tagToDelete: FITag) {
    try {
      if (!_ownTeam) return;

      await _confirm({
        ...DELETE_MODAL_CONFIG,
        title: `${t('preferences.tags.delete.title')} "${tagToDelete.label}"?`,
        description: t('preferences.tags.delete.text'),
      });

      await deleteTag(_ownTeam._id, tagToDelete._id);
      _snackbar.enqueueSnackbar(t('preferences.tags.deleteSuccess'), {
        variant: 'success',
      });
      _setOwnTeam({
        ..._ownTeam,
        preferences: {
          ..._ownTeam.preferences,
          tags: _ownTeam.preferences?.tags?.filter(
            (tag) => tag._id != tagToDelete._id,
          ),
        },
      });
    } catch (error) {
      if (error) {
        _snackbar.enqueueSnackbar(
          t('error-states.default', {
            variant: 'error',
          }),
        );
      }
    }
  }

  async function _editTag(tag_id: string, new_label: string) {
    try {
      if (!_ownTeam) return;
      _snackbar.enqueueSnackbar(t('preferences.tags.editSuccess'), {
        variant: 'success',
      });
      _setOwnTeam({
        ..._ownTeam,
        preferences: {
          ..._ownTeam.preferences,
          tags: _ownTeam.preferences?.tags?.map((tag) =>
            tag._id == tag_id ? {_id: tag._id, label: new_label} : tag,
          ),
        },
      });
      await editTag(_ownTeam._id, {tag_id, new_label});
    } catch (error) {
      if (error) {
        _snackbar.enqueueSnackbar(
          t('error-states.default', {
            variant: 'error',
          }),
        );
      }
    }
  }

  function _onSetLeagueTeams(teamIds: string[]) {
    const _leagueTeams = _teams.filter((team) => teamIds.includes(team._id));
    _setLeagueTeams(_leagueTeams);
  }

  function _clear() {
    _setPlayers([]);
    _setOwnPlayers([]);
    _setFormations(null);
    _setOwnTeam(null);
  }

  return createProvider(context, props, {
    all: _teams,
    current: _currentTeam,
    getAll: _getTeams,
    setCurrent: _setCurrent,
    ownTeam: _ownTeam,
    leagueTeams: _leagueTeams,
    players: _players,
    ownPlayers: _ownPlayers,
    getPlayers: _getPlayers,
    getOwnPlayers: _getOwnPlayers,
    formations: _formations,
    ownTeamFormations: _ownTeamFormations,
    getFormations: _getFormations,
    getPlayerById: _getPlayerById,
    clear: _clear,
    nextOpponentId: _nextOpponentId,
    opponentsOptions: _opponentsOptions,
    setOpponentsOptions: _setOpponentsOptions,
    getOwnTeam: _getOwnTeam,
    setOwnTeam: _setOwnTeam,
    getTagCountData,
    deleteTag: _deleteTag,
    editTag: _editTag,
    allPlayerCountries: _allPlayerCountries,
    setLeagueTeams: _onSetLeagueTeams,
  });
};
