import React, {useEffect, useState} from 'react';

import Autocomplete from '@/components/material-customised/autocomplete/autocomplete.view';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import LoadingButton from '@mui/lab/LoadingButton';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import {DatePicker} from '@mui/x-date-pickers/DatePicker';

import {useTranslation} from 'react-i18next';
import {
  FIFileUploadPostData,
  FIUploadedFile,
  UPLOAD_CATEGORIES,
} from '@my-game-plan/types';
import {useTeams} from '@/context/team.context';
import {useAuth} from '@/context/auth.context';
import {
  editFileDocument,
  createFileDocument,
  generateFileUploader,
} from '@/controllers/file-upload.controller';
import {LOADING_STATE} from '@/types/screen.types';
import {useFormik} from 'formik';

import {ITypedOption} from '@/types/option.types';
import {getPlayerOptions} from '@/helpers/automation/automation-form.helper';
import moment, {Moment} from 'moment';
import * as yup from 'yup';
import FileUploadInput from './file-upload-input.view';
import {useSnackbar} from 'notistack';
import {useConfirm} from 'material-ui-confirm';
import {useFileUploads} from '@/context/file-uploads.context';
import {useAnalytics} from '@/context/analytics.context';
import ANALYTICS_EVENT from '@/config/analytics/event-names.config';
import {removeExtensionFromFileName} from '@/helpers/file-upload.helper';

interface IFileUploadFormProps {
  isOpened: boolean;
  onClose: () => void;
  data?: FIUploadedFile;
}

function FileUploadForm(props: IFileUploadFormProps): JSX.Element | null {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const _teamsContext = useTeams();
  const _authContext = useAuth();
  const _snackbar = useSnackbar();
  const _confirm = useConfirm();
  const _filesContext = useFileUploads();
  const _analyticsContext = useAnalytics();

  const [_loadingState, _setLoadingState] = React.useState<LOADING_STATE>(
    LOADING_STATE.INITING,
  );
  const [_uploadProgress, _setUploadProgress] = useState<number>(0);

  const _validationSchema = yup.object<FIFileUploadPostData>({
    file: props.data ? yup.mixed() : yup.mixed().required(),
    title: yup.string().required(),
    category: yup.string().required(),
    description: yup.string(),
    expiration_date: yup.date(),
    shared_with_players: yup.array().of(yup.string()),
  });

  const _initialValues: Partial<FIFileUploadPostData> = props.data
    ? {
        title: props.data.title,
        description: props.data.description,
        category: props.data.category,
        shared_with_players: props.data.shared_with_players,
        team_id: props.data.team_id,
        uploaded_by: props.data.uploaded_by,
        expiration_date: props.data.expiration_date,
      }
    : {
        title: '',
        description: '',
        category: undefined,
        shared_with_players: [],
        team_id: _authContext.user?.team || '',
        uploaded_by: _authContext.user?._id || '',
        expiration_date: undefined,
      };

  const _formik = useFormik<Partial<FIFileUploadPostData>>({
    onSubmit: _onSubmit,
    initialValues: _initialValues,
    enableReinitialize: true,
    validationSchema: _validationSchema,
  });

  const [_playerOptions, _setPlayerOptions] = useState<ITypedOption<string>[]>(
    [],
  );
  const [_selectedPlayerOptions, _setSelectedPlayerOptions] = useState<
    ITypedOption<string>[]
  >([]);

  /*
   * Side effects
   */

  // Generate player options based on ownPlayers
  useEffect(() => {
    const _generatedPlayerOptions = getPlayerOptions(_teamsContext.ownPlayers);
    _setPlayerOptions(_generatedPlayerOptions);
  }, [_teamsContext.ownPlayers]);

  // Set default expiration date to next month
  useEffect(() => {
    if (props.isOpened && !props.data) {
      const _nextMonth = moment().add(1, 'month').toDate();

      _formik.setFieldValue('expiration_date', _nextMonth);
    }
  }, [props.isOpened, props.data]);

  // Get selected player options based on shared_with_players (which are player ids)
  useEffect(() => {
    const _newSelectedPlayerOptions = _playerOptions.filter((option) =>
      _formik.values.shared_with_players?.includes(option.value),
    );
    _setSelectedPlayerOptions(_newSelectedPlayerOptions);
  }, [_playerOptions, _formik.values.shared_with_players]);
  /*
   * Handlers
   */
  async function _onSubmit() {
    if (!_teamsContext.ownTeam || !_authContext.user) {
      return;
    }
    try {
      _setLoadingState(LOADING_STATE.LOADING);

      const {file, ..._formikValues} = _formik.values;

      if (file) {
        _formikValues.file_metadata = {
          name: file.name,
          size: file.size,
          type: file.type,
        };
      }

      _setUploadProgress(1);

      /* Initial request: create or update file document */
      let _id = null;
      if (props.data) {
        // Update MongoDB doc
        await editFileDocument(props.data._id, _formikValues);
        _id = props.data._id;
      } else {
        // Create new MongoDB doc
        const _newDoc = await createFileDocument(_formikValues);
        _id = _newDoc._id;

        _analyticsContext.trackEvent(ANALYTICS_EVENT.UPLOADED_FILE, {
          file_id: _newDoc._id,
          size: _newDoc.file_metadata.size,
          type: _newDoc.type,
        });
      }

      if (!_id || !_formikValues.team_id) {
        throw new Error('Missing teamId or file id');
      }

      if (file) {
        /* Upload file to S3 */
        const _fileUploader = generateFileUploader(
          _id,
          _formikValues.team_id,
          file as File,
        );

        _fileUploader.on('httpUploadProgress', (progress) => {
          // console.log('Upload progress', progress);
          if (progress?.loaded && progress?.total) {
            const _progress = Math.round(
              (progress.loaded / progress.total) * 100,
            );
            _setUploadProgress(_progress);
          }
        });

        await _fileUploader.done();

        /* Update file status */
        await editFileDocument(_id, {
          upload_status: 'completed',
          team_id: _formikValues.team_id,
        });
      }

      _filesContext.getAllFiles();

      _onClose(false);
    } catch (error) {
      _setUploadProgress(0);
      _setLoadingState(LOADING_STATE.INITING);
      _snackbar.enqueueSnackbar(t('uploads.uploadForm.error.default'), {
        variant: 'error',
      });
    }
  }

  async function _onClose(shouldConfirm = true) {
    if (!shouldConfirm) {
      props.onClose();
      return;
    }

    try {
      if (_formik.dirty) {
        await _confirm({
          title: t('uploads.uploadForm.closeWarning.title'),
          confirmationText: t('uploads.uploadForm.closeWarning.text'),
          description: t('uploads.uploadForm.closeWarning.description'),
        });
      }

      _formik.resetForm();
      _setUploadProgress(0);
      props.onClose();
    } catch (error) {
      // user closed modal
    }
  }

  function _onDiscard() {
    _onClose();
  }

  function _onFileSelect(file: File) {
    // _setFile(file);

    _formik.setFieldValue('file', file);

    _formik.setFieldValue('file', file);

    if (!_formik.values.title) {
      const _fileName = removeExtensionFromFileName(file.name);
      _formik.setFieldValue('title', _fileName);
    }
  }

  function _onPlayerSelect(
    value: ITypedOption<string> | ITypedOption<string>[] | null,
  ) {
    //

    if (Array.isArray(value)) {
      // Handle multiselect
      _formik.setFieldValue(
        'shared_with_players',
        value.map((v) => v.value),
      );
    } else {
      // Handle single select
    }
  }

  function _onDateChange(date: Moment | null) {
    _formik.setFieldValue('expiration_date', date?.toDate() || undefined);
  }

  function _onDateClear() {
    _formik.setFieldValue('expiration_date', null);
  }

  function _onExited() {
    // _formik.resetForm();
  }

  /*
   * Render
   */
  function _positionsGroupHeaderKey(text: string): string {
    return t(`playerPosition.${text}s`);
  }

  function _generateLabel(key: string, required = true): string {
    let _label = t(`${key}`);

    if (!required) {
      _label += ` (${t('uploads.fields.optional')})`;
    }
    return _label;
  }

  const _title = props.data
    ? t('uploads.uploadForm.editTitle')
    : t('uploads.uploadForm.title');
  const _submitText = props.data
    ? t('uploads.uploadForm.update')
    : t('uploads.uploadForm.submit');
  return (
    <Dialog
      open={props.isOpened}
      scroll="body"
      onClose={_onDiscard}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        component: 'form',
        onSubmit: _formik.handleSubmit,
      }}
      TransitionProps={{
        onExited: _onExited,
      }}>
      <DialogTitle>{_title}</DialogTitle>
      <DialogContent>
        <Stack gap={6}>
          {/* File upload */}
          <FileUploadInput
            onChange={_onFileSelect}
            error={
              (_formik.touched.file || Boolean(_formik.submitCount)) &&
              Boolean(_formik.errors.file)
            }
            value={_formik.values.file || props.data?.file_metadata}
            uploadProgress={_uploadProgress}
          />

          {/* FORM INPUTS */}
          <Stack gap={3}>
            <Grid container spacing={2}>
              <Grid item xs={8}>
                {/* TITLE */}
                <TextField
                  label={_generateLabel('uploads.fields.title')}
                  placeholder={_generateLabel('uploads.fields.title')}
                  name="title"
                  onChange={_formik.handleChange}
                  value={_formik.values.title}
                  onBlur={_formik.handleBlur}
                  error={_formik.touched.title && Boolean(_formik.errors.title)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                {/* CATEGORY */}
                <FormControl fullWidth>
                  <InputLabel id="category">
                    {_generateLabel('uploads.fields.category')}
                  </InputLabel>
                  <Select
                    labelId="category"
                    label={_generateLabel('uploads.fields.category')}
                    name="category"
                    value={_formik.values.category || ''}
                    onChange={_formik.handleChange}
                    onBlur={_formik.handleBlur}
                    error={
                      _formik.touched.category &&
                      Boolean(_formik.errors.category)
                    }
                    MenuProps={{
                      slotProps: {
                        paper: {
                          sx: {
                            backgroundColor: 'background.default',
                          },
                        },
                      },
                    }}>
                    {UPLOAD_CATEGORIES.map((category) => (
                      <MenuItem key={category} value={category}>
                        {t(`uploads.categories.${category}`)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            {/* DESCRIPTION */}
            <TextField
              multiline
              label={_generateLabel('uploads.fields.description', false)}
              placeholder={_generateLabel('uploads.fields.description', false)}
              name="description"
              onChange={_formik.handleChange}
              value={_formik.values.description}
              minRows={3}
            />

            {/* SHARED WITH PLAYERS */}

            <Autocomplete
              options={_playerOptions}
              multiple
              label={_generateLabel('uploads.fields.sharedWithPlayers', false)}
              componentsProps={{
                paper: {
                  sx: {bgcolor: 'background.default'},
                },
              }}
              onChange={_onPlayerSelect}
              groupHeader={_positionsGroupHeaderKey}
              disableCloseOnSelect
              value={_selectedPlayerOptions}
            />

            {/* EXPIRATION DATE */}
            <DatePicker
              label={_generateLabel('uploads.fields.expirationDate', false)}
              name="expiration_date"
              onChange={_onDateChange}
              value={
                _formik.values.expiration_date
                  ? moment(_formik.values.expiration_date as Date)
                  : null
              }
              disablePast
              slotProps={{
                field: {clearable: true, onClear: _onDateClear},
              }}
            />
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={_onDiscard} color="secondary">
          {t('general.cancel')}
        </Button>
        <LoadingButton
          loading={_loadingState === LOADING_STATE.LOADING}
          variant="contained"
          type="submit">
          {_submitText}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

export default FileUploadForm;
