import React, {useEffect, useState} from 'react';

import {
  FIBenchmarkOptions,
  FIBenchmarkTimeOptions,
  FICompactMatchInfo,
  FIMatch,
  TBenchmarkDisabledReason,
} from '@my-game-plan/types';

import Stack from '@mui/material/Stack';
import BenchmarkButton, {
  IBenchmarkButtonTrackerInfo,
} from './buttons/benchmark-button.view';
import TimeFilterButton from './buttons/time-filter/time-filter-button.view';
import {IMatchFilterConfig} from './buttons/matches-filter/matches-filter-popup.view';
import PlayersFilterButton from './buttons/players-filter/players-filter-button.view';
import OpponentFilterButton from './buttons/opponent-filter/opponent-filter-button.view';
import {useAnalytics} from '@/context/analytics.context';
import ANALYTICS_EVENT from '@/config/analytics/event-names.config';

// Benchmark
interface IHeaderFilterBarBenchmarkProps {
  benchmarkOptions?: FIBenchmarkOptions;
  onBenchmark(options: FIBenchmarkOptions): Promise<void>;
  benchmarkDisabledReason?: TBenchmarkDisabledReason;
}

// Time range
interface IHeaderFilterBarTimeRangeProps {
  filters?: FIBenchmarkTimeOptions;
  onFiltersChange: (filters: FIBenchmarkTimeOptions) => void;
  observingMatchIds?: string[];
  onObservingMatchIdsChange?: (matchIds: string[]) => void;
  includedMatches?: FIMatch[] | FICompactMatchInfo[];
  canCheckMatches?: boolean;
}

// Players
interface IPlayerFilterProps {
  selectedPlayerIds: string[];
  onPlayerSelect: (playerId: string) => void;
}

interface IOpponentFilterProps {
  selectedOpponentId: string | null;
  onOpponentSelect: (opponentId: string) => void;
}

interface IHeaderFilterBarProps {
  benchmark: IHeaderFilterBarBenchmarkProps;
  timeFilters: IHeaderFilterBarTimeRangeProps;
  trackerInfo?: IBenchmarkButtonTrackerInfo;
  screen: string;

  playerFilter?: IPlayerFilterProps;

  opponentFilter?: IOpponentFilterProps;

  ExtraComponent?: React.ReactNode;
}

function HeaderFilterBar(props: IHeaderFilterBarProps): JSX.Element {
  /*
   * Hooks n State
   */
  const _analyticsContext = useAnalytics();
  const [_matchFilterConfig, _setMatchFilterConfig] = useState<
    IMatchFilterConfig | undefined
  >(undefined);

  /*
   * Side effects
   */
  useEffect(() => {
    if (!props.timeFilters.canCheckMatches) {
      _setMatchFilterConfig(undefined);
      return;
    }

    _setMatchFilterConfig({
      observingMatchIds: props.timeFilters.observingMatchIds || [],
      onMatchSelect: (matchIds: string[]) => {
        if (props.timeFilters.onObservingMatchIdsChange) {
          props.timeFilters.onObservingMatchIdsChange(matchIds);
        }
      },
      allMatches: props.timeFilters.includedMatches || [],
    });
  }, [
    props.timeFilters.observingMatchIds,
    props.timeFilters.includedMatches,
    props.timeFilters.canCheckMatches,
    props.timeFilters.onObservingMatchIdsChange,
  ]);

  /*
   * Handlers
   */
  async function _onBenchmark(options: FIBenchmarkOptions) {
    _analyticsContext.trackEvent(ANALYTICS_EVENT.CHANGED_BENCHMARK_FILTERS, {
      benchmark_options: props.benchmark.benchmarkOptions,
      screen: props.screen,
    });

    props.benchmark.onBenchmark(options);
  }

  async function _onOwnFiltersChange(filters: FIBenchmarkTimeOptions) {
    _analyticsContext.trackEvent(ANALYTICS_EVENT.CHANGED_OWN_DATA_FILTERS, {
      screen: props.screen,
      filters: filters,
    });

    props.timeFilters.onFiltersChange(filters);
  }

  /*
   * Render
   */

  return (
    <Stack direction="row" alignItems="center">
      {/* Own data + Other filters */}
      <Stack
        flex={1}
        gap={4}
        direction="row"
        alignItems="center"
        justifyContent="ftlex-start">
        {/* Opponents */}
        {props.opponentFilter && (
          <OpponentFilterButton {...props.opponentFilter} />
        )}

        {/* Time filter */}
        <TimeFilterButton
          onRangeChange={_onOwnFiltersChange}
          value={props.timeFilters.filters}
          matchFilterConfig={_matchFilterConfig}
          observingMatchesCount={
            props.timeFilters.canCheckMatches
              ? props.timeFilters.observingMatchIds?.length
              : undefined
          }
        />

        {/* Players - Trackers only */}
        {props.playerFilter && (
          <PlayersFilterButton
            selectedPlayerIds={props.playerFilter.selectedPlayerIds}
            onPlayerSelect={props.playerFilter.onPlayerSelect}
          />
        )}

        {/* Extra component */}
        {props.ExtraComponent}
      </Stack>

      {/* Benchmark */}
      <BenchmarkButton
        onBenchmark={_onBenchmark}
        benchmarkOptions={props.benchmark.benchmarkOptions}
        benchmarkDisabledReason={props.benchmark.benchmarkDisabledReason}
        trackerInfo={props.trackerInfo}
      />
    </Stack>
  );
}

export default HeaderFilterBar;
