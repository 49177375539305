import {useAPI} from '@/hooks/api.hooks';
import {Resource} from '@/types/api.types';
import {TEAMS_API_CONFIG} from '@/config/api.config';
import {
  ACTION,
  CountTagUsageBody,
  EditTagBody,
  F,
  FIAutomationDataQueryParams,
  FIPlayerOverview,
  FITeam,
  FITeamFormatted,
  ITeamFormations,
  RunPreference,
} from '@my-game-plan/types';
import {DEFAULT_FORMATIONS} from '@/types/team.types';
import {REQUEST_ERRORS} from '@/config/errors.config';

const api = useAPI();

export const getTeams = async (
  userID: string | undefined,
): Promise<FITeamFormatted[]> => {
  try {
    const {data} = await api.get<FITeam[]>({
      resource: Resource.teams,
      url: TEAMS_API_CONFIG.getTeams,
      params: {
        user_id: userID,
      },
    });
    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }
    return data;
  } catch {
    return new Promise<FITeamFormatted[]>((res) => res([]));
  }
};

export const getTeam = async (teamID: string): Promise<FITeam> => {
  try {
    const {data} = await api.get<FITeam>({
      resource: Resource.teams,
      url: TEAMS_API_CONFIG.getTeam(teamID),
    });
    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }
    return data;
  } catch (error) {
    // return new Promise<FITeamFormatted>((res) => res([]));
    throw new Error((error as any).message);
  }
};

export const getTeamFormations = async (
  teamID: string | undefined,
): Promise<ITeamFormations> => {
  try {
    const {data} = await api.get<ITeamFormations>({
      resource: Resource.teams,
      url: TEAMS_API_CONFIG.getTeamFormations(teamID),
    });
    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }
    return data;
  } catch {
    return new Promise<ITeamFormations>((res) => res(DEFAULT_FORMATIONS));
  }
};

export const updateRunPreferences = async (
  teamID: string,
  action: ACTION,
  preferences: RunPreference,
): Promise<void> => {
  try {
    const {data} = await api.put({
      resource: Resource.teams,
      url: TEAMS_API_CONFIG.updateRunPreferences(teamID, action),
      data: preferences,
    });
    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }
    return;
  } catch (e) {
    console.log(e);
  }
};

export async function getTagCount(teamID: string) {
  try {
    const {data} = await api.get<F<CountTagUsageBody>>({
      resource: Resource.teams,
      url: TEAMS_API_CONFIG.getTagCount(teamID),
    });
    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }
    return data;
  } catch (e) {
    console.log(e);
  }
}

export async function deleteTag(teamID: string, tagToDelete: string) {
  try {
    const {data} = await api.del({
      resource: Resource.teams,
      url: TEAMS_API_CONFIG.deleteTag(teamID, tagToDelete),
    });
    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }
    return data;
  } catch (e) {
    console.log(e);
  }
}

export async function editTag(teamID: string, editTagBody: EditTagBody) {
  try {
    const {data} = await api.put({
      resource: Resource.teams,
      url: TEAMS_API_CONFIG.editTag(teamID),
      data: editTagBody,
    });
    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }
    return data;
  } catch (e) {
    console.log(e);
  }
}

interface ISearchTeamQuery {
  q?: string;
  team_id?: string[];
}
export async function searchTeams(query: ISearchTeamQuery): Promise<FITeam[]> {
  try {
    const {data} = await api.get<FITeam[]>({
      resource: Resource.teams,
      url: TEAMS_API_CONFIG.search(),
      params: query,
    });

    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }

    return data;
  } catch (error) {
    throw new Error((error as any).message);
  }
}

// Get team overview
export async function getTeamOverview(
  teamID: string,
  filters?: FIAutomationDataQueryParams,
): Promise<FIPlayerOverview> {
  try {
    const {data} = await api.get<FIPlayerOverview>({
      resource: Resource.teams,
      url: TEAMS_API_CONFIG.getTeamOverview(teamID),
      params: filters,
    });
    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }
    return data;
  } catch (error) {
    throw new Error((error as any).message);
  }
}
