import {ACTION, TDataProvider, TShotAnalysisTeam} from '@my-game-plan/types';
import {JobId} from 'bull';

export const LOCAL_STORAGE_USER_ID = 'mygameplan-userId';
export const ASSIGNED_API_CONFIG = {
  addAssigned: '',
  editAssigned: (id: string) => `${id}`,
  deleteAssigned: (id: string) => `${id}`,
  getStaticAssignedForTeam: (id: string) => `teams/${id}/static`,
  getRecurringAssignedForTeam: (id: string) => `teams/${id}/recurring`,
  fetchAssignedEvents: (id: string) => `${id}/events`,
};

export const AUTH_API_CONFIG = {
  authenticateUser: '',
  logoutUser: 'logout',
};

export const USERS_API_CONFIG = {
  fetchUser: (id: string) => `${id}`,
};

export const DASHBOARD_API_CONFIG = {
  getDashboard: (team: string, action: string) => `${team}/${action}`,
  getDashboardDataExplorer: (team: string, action: string) =>
    `${team}/${action}/data-explorer`,
};

export const SCORES_API_CONFIG = {
  getDashboardScores: (team: string, action: string) => `${team}/${action}`,
};

export const VIDEO_API_CONFIG = {
  getEditedClip: 'edited_clip',
};

export const EVENTS_API_CONFIG = {
  getEvent: (id: string | number) => `${id}`,
};

export const MATCHES_API_CONFIG = {
  getMatchesByTeam: (teamId: string | undefined) => `team/${teamId}`,
};

export const WIDGETS_API_CONFIG = {
  getPlaceholderWidgets: (dataProvider: TDataProvider) => `${dataProvider}`,
};

export const TEAMS_API_CONFIG = {
  getOverviewDashboards: (id: string) => `${id}/overview-dashboards`,
  getOverviewDashboard: (teamId: string, id: string) =>
    `${teamId}/overview-dashboards/${id}`,
  addOverviewDashboard: (teamId: string) => `${teamId}/overview-dashboards`,
  editOverviewDashboard: (teamId: string, id: string) =>
    `${teamId}/overview-dashboards/${id}`,
  deleteOverviewDashboard: (teamId: string, id: string) =>
    `${teamId}/overview-dashboards/${id}`,
  getTeam: (teamID: string) => `${teamID}`, //TODO params
  getTeamOverview: (teamID: string) => `${teamID}/overview`,
  getTeams: '', //TODO params
  getTeamFormations: (teamId: string | undefined) => `${teamId}/formations`,
  updateRunPreferences: (teamId: string, action: ACTION) =>
    `${teamId}/preferences/action/${action}`,
  getTagCount: (teamId: string) => `${teamId}/preferences/tag`,
  deleteTag: (teamId: string, tagToDelete: string) =>
    `${teamId}/preferences/tag/${tagToDelete}`,
  editTag: (teamId: string) => `${teamId}/preferences/tag`,

  search: () => 'search',
};

export const PLAYER_STATISTICS_API_CONFIG = {
  getPlayerStatistics: '',
};

export const PLAYERS_API_CONFIG = {
  getPlayersByTeam: (teamId: string | undefined) => `team/${teamId}`,
  getPlayerPerformance: (teamId: string, playerId?: string) => {
    return `${teamId}/${playerId}/performance`;
  },
  search: (teamID: string) => `search/${teamID}`,
  scoutedPlayers: (teamID: string) => `search/${teamID}/scouting`,
  getCountries: () => 'countries',
  startScouting: () => 'scouting',
  scoutPerformance: () => 'scouting/performance',
  stopScouting: (teamId: string, playerId: string) =>
    `scouting/${teamId}/${playerId}`,
  getOverview: (teamId: string, playerId: string) =>
    `${teamId}/${playerId}/overview`,
};

export const ACTION_API_CONFIG = {
  getActionConfigs: '',
};

export const AUTOMATION_API_CONFIG = {
  getAll: (team_id: string) => `all/${team_id}`,
  getAllGroupedPerPlayer: (team_id: string) => `players/${team_id}`,
  getOne: (id: string) => `${id}`,
  getPitchVisual: (id: string) => `${id}/pitch-visual`,
  getEvents: (id: string) => `${id}/events`,
  addOne: '',
  editOne: (id: string) => `${id}`,
  shareOne: (id: string) => `${id}/share`,
  deleteOne: (id: string) => `${id}`,
  updateDataForAutomations: (teamId: string) => `team/${teamId}/update-data`,
  benchmarkOne: (id: string) => `${id}/benchmark`,
};

export const CLIPS_API_CONFIG = {
  getVideo: (eventID: string, wyscoutID: string) => `${wyscoutID}/${eventID}`, //TODO params,
  downloadVideos: 'download',
  saveEditedClip: (team_id: string, event_id: string) =>
    `${team_id}/${event_id}`,
};

export const ZONES_API_CONFIG = {
  getZones: (teamID: string) => `${teamID}`,
};

export const SHOT_ANALYSIS_API_CONFIG = {
  getShotAnalysis: (teamID: string, type: TShotAnalysisTeam) =>
    `${teamID}/${type}`,
  getEvents: (teamID: string, type: TShotAnalysisTeam) =>
    `${teamID}/${type}/events`,
  getInsights: (teamID: string, type: TShotAnalysisTeam) =>
    `${teamID}/${type}/insights`,
};

export const BACKGROUND_JOBS_API_CONFIG = {
  getJobStatus: (worker: string, jobId: JobId) => `${worker}/${jobId}/status`,
};

export const FILE_UPLOAD_CONFIG = {
  uploadFile: () => '',
  getAllFiles: (teamId: string) => `all/${teamId}`,
  getSingleFile: (id: string) => `${id}`,
  editFile: (id: string) => `${id}`,
  deleteFile: (id: string) => `${id}`,
};

export const ADMIN_API_CONFIG = {
  switchTeam: 'switch-team',
};

export const PLAYER_OBJECTIVES_API_CONFIG = {
  getAllForPlayer: (teamId: string, playerId: string) =>
    `all/${teamId}/${playerId}`,
  createObjective: () => '',
  singleObjective: (id: string) => `${id}`,
  comment: (id: string) => `${id}/comments`,
  singleComment: (id: string, commentId: string) =>
    `${id}/comments/${commentId}`,
};

export const COMPETITIONS_API_CONFIG = {
  getCompetitionSeasons: () => 'seasons',
};
