import React, {useEffect, useState} from 'react';

import {useTranslation} from 'react-i18next';
import Legend from '../../common/legend/Legend';
import Pitch from './Pitch';

import {
  IPitchVisual,
  IPitchVisualEventGroup,
} from '@/types/dashboard/dashboard-pitch-visual.types';

import VideoPlayerButton from '../../buttons/VideoPlayerButton';
import styles from './pitch-visual.module.scss';
import sortEvents from '@/helpers/dashboard/dashboard.helper';
import {
  ACTION,
  FICompactMatchInfo,
  FIConditionalEventFilters,
  FIEventFilters,
  FIMatch,
  FIMatchEvent,
  FIPitchZone,
  PitchVisualFocusType,
  TEventCalculationType,
} from '@my-game-plan/types';
import Tabs from '@/components/material-customised/tabs/tabs.view';
import Tab from '@mui/material/Tab';

interface PitchVisualProps {
  data: IPitchVisual;
  action: ACTION;
  details?: FIConditionalEventFilters;
  activeTab: number;
  isOwnTeam?: boolean;
  zones?: FIPitchZone[];
  teamLogo?: string;
  isObservingOpponent?: boolean;
  calculation?: TEventCalculationType;
  metric?: keyof FIEventFilters;
  matches?: FIMatch[] | FICompactMatchInfo[];
}

const PitchVisual = ({
  data,
  action,
  activeTab,
  details,
  zones,
  isOwnTeam = false,
  teamLogo,
  isObservingOpponent,
  calculation,
  metric,
  matches,
}: PitchVisualProps): JSX.Element => {
  /* Hooks n State */
  const {t} = useTranslation();

  const [_selectedTabIndex, _setSelectedTabIndex] = useState<number>(0);
  const [_visibleEvents, _setVisibleEvents] = useState<FIMatchEvent[] | null>(
    null,
  );
  const [_visibleGroupIndexes, _setVisibleGroupIndexes] = useState<number[]>(
    [],
  );
  const [_groupsToDisplayEventsFor, _setGroupsToDisplayEventsFor] = useState<
    IPitchVisualEventGroup[]
  >([]);
  const [_isHighlightingSuccessful, _setIsHighlightingSuccessful] =
    useState<boolean>(false);

  useEffect(() => {
    if (data.tabs[activeTab]?.groups) {
      const _newVisibleGroupIndexes = data.tabs[activeTab].groups.map(
        (group, index) => {
          return index;
        },
      );
      _setVisibleGroupIndexes(_newVisibleGroupIndexes);
    }
  }, [activeTab, data.tabs]);

  useEffect(() => {
    if (data.tabs[activeTab]?.groups) {
      const _newGroupsToDisplay = data.tabs[activeTab].groups.filter(
        (group, index) => _visibleGroupIndexes.includes(index),
      );

      _setGroupsToDisplayEventsFor(_newGroupsToDisplay);

      const _visibleGroupLabels = _newGroupsToDisplay.map(
        (group) => group.label,
      );
      const _events: FIMatchEvent[] = [];
      for (const group of data.tabs[activeTab].groups) {
        if (_visibleGroupLabels.includes(group.label)) {
          _events.push(...group.events);
        }
      }

      _setVisibleEvents(sortEvents(_events));
    }
  }, [activeTab, data.tabs, _visibleGroupIndexes]);

  /*
   * Handlers
   */

  function _onVisibleGroupIndexesChanged(indexes: number[]) {
    _setVisibleGroupIndexes(indexes);
  }

  const _handleTabChange = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: number,
  ) => {
    _setSelectedTabIndex(newValue);
  };

  function _onHighlightSuccessfulToggle() {
    _setIsHighlightingSuccessful(!_isHighlightingSuccessful);
  }

  /*
   * Render
   */
  const _tabNames = data.displayTypes.map((displayType) => displayType);

  const _PitchNode = (
    <Pitch
      type={data.displayTypes[_selectedTabIndex]}
      eventGroups={_groupsToDisplayEventsFor}
      linkedTable={data.tabs[activeTab].linkedTable}
      showPath={data.showPath || false}
      defaultFocusType={data.defaultFocusType || PitchVisualFocusType.START}
      action={action}
      details={details}
      metric={metric}
      isOwnTeam={isOwnTeam}
      zones={zones}
      teamLogo={teamLogo}
      isObservingOpponent={isObservingOpponent}
      shouldDifferentiateSuccessfulEvents={_isHighlightingSuccessful}
      matches={matches}
    />
  );

  return (
    <div className={styles.field}>
      <div className="nav-separator" />
      <div className={styles.top}>
        <Tabs
          value={_selectedTabIndex}
          onChange={_handleTabChange}
          aria-label="tabs">
          {_tabNames.map((tab, index) => (
            <Tab key={index} label={t(`dashboard.pitchVisual.types.${tab}`)} />
          ))}
        </Tabs>
        <div className={styles.playButton}>
          <VideoPlayerButton
            events={_visibleEvents}
            actionInfo={{action: action, details: details, metric: metric}}
            variant="text"
            matches={matches}
            relative={calculation === 'ratio' || calculation === 'rule'}
          />
        </div>
      </div>

      <div className={styles.legend}>
        <Legend
          linkedTable={data.tabs[activeTab].linkedTable}
          groups={data.tabs[activeTab].groups}
          visibleGroupIndexes={_visibleGroupIndexes}
          onVisibleGroupIndexesChanged={_onVisibleGroupIndexesChanged}
          isHighlightingSuccessfulEvents={_isHighlightingSuccessful}
          calculation={calculation}
          onHighlightSuccessfulHandler={_onHighlightSuccessfulToggle}
        />
      </div>
      {_PitchNode}
    </div>
  );
};

export default PitchVisual;
