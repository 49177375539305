import React, {useEffect, useState} from 'react';

import Logo from '../Logo/Logo';
import {FIMatch, FMatch, TCompetitionType} from '@my-game-plan/types';
import CompetitionIcon from '../competition-icon/competition-icon.view';

type MatchScoreProps = {
  match: FIMatch | FMatch;
};

export const MatchScore = ({match}: MatchScoreProps) => {
  /*
   * Hooks n State
   */
  const [_competitionType, _setCompetitionType] =
    useState<TCompetitionType>('domestic_league');

  /*
   * Side Effects
   */
  // Define whether to show cup icon
  useEffect(() => {
    if ('competition_type' in match) {
      _setCompetitionType(match.competition_type);
    }
  }, [match]);

  /*
   * Render
   */
  return (
    <div style={{display: 'flex', alignItems: 'center', gap: '8px'}}>
      <Logo src={match.home_team?.image_url || ''} size="small" />
      <div>{`${match.home_team_end_score} - ${match.away_team_end_score}`}</div>
      <Logo src={match.away_team?.image_url || ''} size="small" />
      <CompetitionIcon competition_type={_competitionType} size="small" />
    </div>
  );
};
