import Autocomplete from '@/components/material-customised/autocomplete/autocomplete.view';

import {useTeams} from '@/context/team.context';
import {translateEventProperty} from '@/helpers/translation.helper';
import i18n from '@/localization';
import {ITypedOption} from '@/types/option.types';

import {ICommonSentenceInputProps} from '@/types/sentence-form.types';
import {
  FIEventAutomationPostData,
  FIEventFilters,
  generateActionConfigs,
} from '@my-game-plan/types';

import React, {useEffect, useState} from 'react';
import Segment from '..';

type TOptionType = ITypedOption<keyof FIEventFilters | undefined>;

const DEFAULT_OPTIONS: TOptionType[] = [
  {
    label: i18n.t('sentenceForm.amount'),
    name: i18n.t('sentenceForm.amount'),
    value: undefined,
  },
];

function MetricsDropdown<DataType = FIEventAutomationPostData>(
  props: ICommonSentenceInputProps<DataType>,
): JSX.Element {
  /*
   * Hooks n State
   */
  const [_options, _setOptions] = useState<TOptionType[]>(DEFAULT_OPTIONS);
  const [_selectedOption, _setSelectedOption] = useState<TOptionType | null>(
    null,
  );
  const _teamsContext = useTeams();

  /*
   * Side effects
   */
  // Generate options based on data provider
  useEffect(() => {
    if (!_teamsContext.ownTeam || !props.data.action) {
      _setOptions(DEFAULT_OPTIONS);
      _setSelectedOption(DEFAULT_OPTIONS[0]);
      return;
    }

    const _actionsConfig = generateActionConfigs(
      _teamsContext.ownTeam.data_provider,
    );

    const _availableMetrics =
      _actionsConfig[props.data.action]?.availableMetrics || [];
    const _providerOptions = _availableMetrics.map((metric) => {
      const _option: TOptionType = {
        value: metric,
        label: translateEventProperty(metric),
        name: translateEventProperty(metric),
      };
      return _option;
    });
    const _generatedOptions: TOptionType[] = [
      ...DEFAULT_OPTIONS,
      ..._providerOptions,
    ];

    const _selectedOption = _generatedOptions.find(
      (option) => option.value == props.data.metric,
    );
    _setSelectedOption(_selectedOption || null);

    _setOptions(_generatedOptions);
  }, [_teamsContext.ownTeam, props.data.action, props.data.metric]);
  /*
   * Handlers
   */
  function _onChange(selectedOption: TOptionType | TOptionType[] | null): void {
    if (props.onChange && !Array.isArray(selectedOption)) {
      props.onChange({
        metric: selectedOption?.value || undefined,
      });
    }
  }

  /*
   * Render
   */
  if (props.readonly || _options.length < 2) {
    return (
      <Segment.Text
        size={props.size}
        data={props.data}
        text={_selectedOption?.label}
        type={_options.length > 1 ? 'value' : 'default'}
      />
    );
  }

  return (
    <Autocomplete
      blurOnSelect
      isInlineInput
      options={_options}
      value={_selectedOption}
      onChange={_onChange}
    />
  );
}

export default MetricsDropdown;
