import React from 'react';

import PlaylistBarShare from '../playlist-bar-share/playlist-bar-share.view';
import PlaylistItem from '../playlistItem/PlaylistItem';
import styles from './playlistBar.module.scss';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import VideocamOffIcon from '@mui/icons-material/VideocamOff';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import {
  FICompactMatchInfo,
  FIEventFilters,
  FIMatch,
  FIMatchEvent,
} from '@my-game-plan/types';
import {formatDate} from '@/helpers/general/general.helper';

import Stack from '@mui/material/Stack';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {alpha} from '@mui/material/styles';
import {t} from 'i18next';

interface PlaylistBarProps {
  currentClip: FIMatchEvent;
  focusRef: React.RefObject<HTMLDivElement>;
  events: [string, FIMatchEvent[]][];
  isPlaylistBarOpen: boolean;
  togglePlaylistBar: (shouldOpen: boolean) => void;
  checkedList: FIMatchEvent[];
  playClip: (clip: FIMatchEvent) => void;
  toggleSelectAll: (e: React.ChangeEvent<HTMLInputElement>) => void;
  toggleSelectItem: (
    e: React.ChangeEvent<HTMLInputElement>,
    clips: FIMatchEvent[],
  ) => void;
  playlistLength: number;
  isRelative?: boolean;
  metric?: keyof FIEventFilters;
  matches?: FIMatch[] | FICompactMatchInfo[];
}

const PlaylistBar = ({
  currentClip,
  focusRef,
  events,
  isPlaylistBarOpen,
  togglePlaylistBar,
  checkedList,
  playClip,
  playlistLength,
  toggleSelectItem,
  isRelative,
  metric,
  matches,
}: PlaylistBarProps) => {
  /* Hooks n State */

  /* Functions */
  function _isChecked(matchId: string | null = null): boolean {
    if (matchId) {
      const checkedClipIds = checkedList.map((clips) => clips._id);

      const _events = events.find((match) => match[0] === matchId.toString());
      if (!_events) return false;

      return _events[1].every((clip) => checkedClipIds.includes(clip._id));
    } else return playlistLength === checkedList.length;
  }

  /*
   * Handlers
   */
  function _openPlaylistBar() {
    togglePlaylistBar(true);
  }

  function _closePlaylistBar() {
    togglePlaylistBar(false);
  }

  // function _onMatchSelectAll(event: React.ChangeEvent<HTMLInputElement>) {
  //   // event.stopPropagation();
  //   toggleSelectAll(event);
  // }

  /*
   * Render
   */

  return (
    <>
      <div className={styles.videoPlaylistBarButton} ref={focusRef}>
        <IconButton
          title={t('video-player.sidebar.open')}
          onClick={_openPlaylistBar}
          sx={{bgcolor: alpha('#000', 0.3)}}>
          <MenuIcon />
        </IconButton>
      </div>

      {isPlaylistBarOpen && (
        <div className={styles.sidebarContainer}>
          <div className={styles.playlistBarHeader}>
            <h4>
              {t('video-player.sidebar.playlist')} ({playlistLength})
            </h4>
            <div className={styles.playlistBarExit}>
              <IconButton
                onClick={_closePlaylistBar}
                title={t('video-player.sidebar.close')}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>

          <div className={styles.playlistBarContent}>
            <Box>
              {events.map((organizedMatch, index) => {
                const _match = matches?.find(
                  (match) => match._id === organizedMatch[0],
                );
                if (!_match) {
                  return null;
                }

                const _hasVideo = !!_match.video;

                const _ExpandIcon = _hasVideo
                  ? ExpandMoreIcon
                  : VideocamOffIcon;
                return (
                  <Accordion
                    className={styles.Accordion}
                    key={organizedMatch[0]}
                    defaultExpanded={index < 3 && _hasVideo}
                    disabled={!_hasVideo}
                    TransitionProps={{unmountOnExit: true}}>
                    <AccordionSummary
                      className={styles.AccordionSummary}
                      expandIcon={<_ExpandIcon sx={{color: 'white'}} />}>
                      <Stack
                        direction="row"
                        gap={1}
                        justifyContent="center"
                        flex={1}
                        pr={1}>
                        <Box>
                          <Box sx={{mt: 0}}>
                            <Checkbox
                              aria-label="check"
                              onClick={(e) => e.stopPropagation()}
                              onChange={(e) => {
                                toggleSelectItem(e, organizedMatch[1]);
                              }}
                              checked={_isChecked(
                                organizedMatch[1][0].match._id,
                              )}
                              size="small"
                            />
                          </Box>
                        </Box>
                        <Box
                          flex={1}
                          width="100%"
                          className={styles.playlistItemResult}>
                          <img
                            src={organizedMatch[1][0].match.home_team.image_url}
                            alt={organizedMatch[1][0].match.home_team.name}
                          />
                          <span
                            className={
                              styles.playlistItemScore
                            }>{`${organizedMatch[1][0].match.home_team_end_score} - ${organizedMatch[1][0].match.away_team_end_score}`}</span>
                          <img
                            src={organizedMatch[1][0].match.away_team.image_url}
                            alt={organizedMatch[1][0].match.away_team.name}
                          />
                        </Box>
                        <Box className={styles.v_center}>
                          <Typography variant={'body2'} sx={{pt: '2px'}}>
                            {formatDate(organizedMatch[1][0].match.date, 'll')}
                          </Typography>
                        </Box>
                      </Stack>
                    </AccordionSummary>
                    <AccordionDetails className={styles.AccordionDetails}>
                      {organizedMatch[1].map((event) => {
                        const _isActive = currentClip._id === event._id;
                        const _isSelected = checkedList.includes(event);
                        return (
                          <PlaylistItem
                            playlistItem={event}
                            key={event._id}
                            isActive={_isActive}
                            isSelected={_isSelected}
                            isRatio={isRelative}
                            playClip={playClip}
                            toggleSelect={toggleSelectItem}
                            canSelect={true}
                            observingMetric={metric}
                            match={_match}
                          />
                        );
                      })}
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </Box>
          </div>
          <PlaylistBarShare />
        </div>
      )}
    </>
  );
};

export default PlaylistBar;
