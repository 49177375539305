import {
  createContextHook,
  createCustomContext,
  createProvider,
} from '@/helpers/general/context_generators.helper';
import {
  FIEventFilters,
  FIFormattedAutomation,
  FIFormattedAutomationPlayerScores,
  FIMatchEvent,
  FIPitchZone,
  FIBenchmarkOptions,
  TEventAutomationType,
  generateTotalScoresForPlayer,
  TBenchmarkDisabledReason,
  shouldInvertScore,
  getBenchmarkDisabledReason,
  getPercentileScore,
  FIBenchmarkRankingItem,
  FIAutomationPlayerScores,
  FIAutomationPlayerMatch,
  FICompactMatchInfo,
  FIAutomationDataQueryParams,
  TBenchmarkState,
  FIBenchmarkData,
  FIBenchmarkTimeOptions,
  FIAutomationOwnDataQueryParams,
} from '@my-game-plan/types';
import {PropsWithChildren, useEffect, useState} from 'react';
import {useAuth} from '../auth.context';
import {useTeams} from '../team.context';
import {useZones} from '../zones.context';
import {useParams, useSearchParams} from 'react-router-dom';
import {
  benchmarkAutomation,
  fetchEventsForAutomation,
  getSingleAutomation,
} from '@/controllers/event-automations.controller';

import {formatPlayerScores} from '@/helpers/automation/automation-detail-history.helper';
import {extractZonesFromAutomation} from '@/helpers/pitch-zones.helper';
import {useAnalytics} from '../analytics.context';
import ANALYTICS_EVENT from '@/config/analytics/event-names.config';

import {useVideo} from '../video/video.context';
import {
  formatSearchParamsToQueryParams,
  getLastMatches,
} from '@/helpers/automation/automation.helper';
import {serializeParams} from '@/helpers/api.helper';
import {LOADING_STATE} from '@/types/screen.types';

export interface AutomationDetailAPI {
  automation: FIFormattedAutomation | null;
  onAutomationChanged: (automation: FIFormattedAutomation | null) => void;
  automationType: TEventAutomationType;
  automationLoadingState: LOADING_STATE;
  isFetchingEvents: boolean;
  events: FIMatchEvent[];
  visibleEvents: FIMatchEvent[];
  history: FIAutomationPlayerMatch[];
  scores: FIFormattedAutomationPlayerScores | null;

  observingMatchIds: string[];
  observingMatches: FICompactMatchInfo[];
  onObservingMatchIdChange: (selectedMatchIds: string[]) => void;

  observingTeamId: string | undefined;
  onObservingTeamIdChange: (teamId: string) => void;
  onOwnDataFiltersChange: (filters: FIBenchmarkTimeOptions) => Promise<void>;

  zones: FIPitchZone[];

  benchmarkAndDateFilters: FIAutomationDataQueryParams | null;
  benchmarkStatus: TBenchmarkState;
  benchmarkData: FIBenchmarkData | undefined;
  benchmarkError: string | null;
  benchmarkDisabledReason: TBenchmarkDisabledReason | null;
  getBenchmarkInfo: (benchmarkFilters: FIBenchmarkOptions) => Promise<void>;

  benchmarkedScore: number | undefined;
  benchmarkAverage: number | undefined;

  error: boolean;
  allMatches: FICompactMatchInfo[];

  getAutomation: (automationId: string) => Promise<void>;
  getAutomationEvents: (automationId: string) => Promise<void>;
  // clear: () => void;

  isOnlyShowingObjectiveEvents: boolean;
  setIsOnlyShowingObjectiveEvents: (value: boolean) => void;

  openVideoPlayer(clips: FIMatchEvent[], selectedClip?: FIMatchEvent): void;
}

const context = createCustomContext<AutomationDetailAPI>();
export const useAutomationDetail = createContextHook(context);

interface IProviderProps {
  automationType: TEventAutomationType;
}
export function AutomationDetailProvider(
  props: PropsWithChildren<IProviderProps>,
): JSX.Element {
  /*
   * Hooks n State
   */
  const [_automation, _setAutomation] = useState<FIFormattedAutomation | null>(
    null,
  );
  const [_automationLoadingState, _setAutomationLoadingState] =
    useState<LOADING_STATE>(LOADING_STATE.INITING);
  const [_isFetchingEvents, _setIsFetchingEvents] = useState(true);
  const [_events, setEvents] = useState<FIMatchEvent[]>([]);
  const [_visibleEvents, setVisibleEvents] = useState<FIMatchEvent[]>([]);
  const [_history, _setHistory] = useState<FIAutomationPlayerMatch[]>([]);
  const [_isOnlyShowingObjectiveEvents, _setIsOnlyShowingObjectiveEvents] =
    useState<boolean>(false);

  const [_scores, _setScores] =
    useState<FIFormattedAutomationPlayerScores | null>(null);

  const [_totalScores, _setTotalScores] =
    useState<FIAutomationPlayerScores | null>(null);

  const [_observingMatchIds, _setObservingMatchIds] = useState<string[]>([]);
  const [_observingMatches, _setObservingMatches] = useState<
    FICompactMatchInfo[]
  >([]);
  const [_observingTeamId, _setObservingTeamId] = useState<string | undefined>(
    undefined,
  );

  const [_zones, _setZones] = useState<FIPitchZone[]>([]);

  const [_benchmarkDisabledReason, _setBenchmarkDisabledReason] =
    useState<TBenchmarkDisabledReason | null>(null);
  // const [_benchmarkInfo, _setBenchmarkInfo] = useState<FIBenchmarkInfo | null>(
  //   null,
  // );
  const [_benchmarkAndDateFilters, _setBenchmarkAndDateFilters] =
    useState<FIAutomationDataQueryParams | null>(null);
  const [_benchmarkStatus, _setBenchmarkStatus] =
    useState<TBenchmarkState>('none');
  const [_benchmarkData, _setBenchmarkData] = useState<
    FIBenchmarkData | undefined
  >(undefined);
  const [_benchmarkError, _setBenchmarkError] = useState<string | null>(null);
  const [_benchmarkedScore, _setBenchmarkedScore] = useState<
    number | undefined
  >(undefined);
  const [_benchmarkAverage, _setBenchmarkAverage] = useState<
    number | undefined
  >(undefined);

  const [_error, _setError] = useState<boolean>(false);

  const [_allMatches, _setAllMatches] = useState<FICompactMatchInfo[]>([]);
  const [_searchParams, _setSearchParams] = useSearchParams();

  const _params = useParams();

  const _authContext = useAuth();
  const _teamsContext = useTeams();
  const _zonesContext = useZones();
  const _analyticsContext = useAnalytics();
  const _videoContext = useVideo();

  /*
   * Side effects
   */
  /* Get automation when params.id changes */
  useEffect(() => {
    if (
      _params.id &&
      _teamsContext.ownTeam &&
      _observingTeamId &&
      props.automationType &&
      _automationLoadingState === LOADING_STATE.INITING
    ) {
      const _parsedQuery = formatSearchParamsToQueryParams(_searchParams);
      _getAutomation(_params.id, _observingTeamId, _parsedQuery);
    }
  }, [
    _params.id,
    _teamsContext.ownTeam,
    _observingTeamId,
    props.automationType,
    _searchParams,
    _automationLoadingState,
  ]);

  /* Get observing team */
  useEffect(() => {
    const _teamIdfromQuery = _searchParams.get('team._id');

    let _teamToObserve =
      _teamIdfromQuery || _teamsContext.ownTeam?._id || undefined;
    if (
      props.automationType === 'opponent-automation' &&
      _teamsContext.nextOpponentId
    ) {
      _teamToObserve = _teamsContext.nextOpponentId;
    } else if (
      props.automationType === 'opponent-automation' &&
      !_teamsContext.nextOpponentId
    ) {
      _setError(true);
    }

    _setObservingTeamId(_teamToObserve);
  }, [
    _teamsContext.ownTeam,
    _teamsContext.nextOpponentId,
    props.automationType,
    _searchParams,
  ]);

  /* Log analytics event */
  useEffect(() => {
    if (_authContext.user && _params.id) {
      const _analyticsEvent =
        props.automationType === 'tracker'
          ? ANALYTICS_EVENT.VIEWED_TRACKERS_DETAIL
          : ANALYTICS_EVENT.VIEWED_OPPONENT_AUTOMATION;
      _analyticsContext.trackEvent(_analyticsEvent, {
        id: _params.id,
        team_id: _authContext.user.team,
      });
    }
  }, [_params.id, _authContext.user]);

  /* When automation was fetched, get events */
  useEffect(() => {
    if (_automation && _observingTeamId && _teamsContext.ownTeam) {
      _getAutomationEvents(_automation._id, _benchmarkAndDateFilters?.own_data);
    }
  }, [
    _automation,
    props.automationType,
    _observingTeamId,
    _teamsContext.ownTeam,
  ]);

  /* Recalculate scores */
  useEffect(() => {
    if (_observingMatchIds?.length && _automation?.players?.length) {
      _setScoresHandler(
        _benchmarkAverage,
        _benchmarkAndDateFilters?.benchmark?.calculation === 'per_90',
      );
    } else {
      _setScores(null);
    }
  }, [
    _observingMatchIds,
    _automation?.players,
    _benchmarkAverage,
    _benchmarkAndDateFilters,
  ]);

  // update the position and scores of the observing player in the benchmark ranking
  useEffect(() => {
    _updateObservingPlayerInBenchmark();
  }, [_totalScores, _benchmarkAndDateFilters]);

  // update the position and scores of the observing player in the benchmark ranking

  /* Parse zone info */
  useEffect(() => {
    let _zones: FIPitchZone[] = [];
    const _zoneIds = extractZonesFromAutomation(_automation);
    _zones = _zonesContext.all.filter((zone) => _zoneIds.includes(zone._id));
    _setZones(_zones);
  }, [_automation, _zonesContext.all]);

  /* Init benchmark filters */
  useEffect(() => {
    if (!_automation) {
      return;
    }

    //}
    const _shouldBenchmark = !getBenchmarkDisabledReason(_automation);
    if (_shouldBenchmark) {
      const _initialFilters: FIAutomationDataQueryParams = {
        benchmark: _automation.benchmark_filters || {},
        own_data: {
          time: _automation.own_filters,
        },
      };
      _getAutomationBenchmarkData(_initialFilters);

      return;
    }

    _setBenchmarkData(undefined);
    _setBenchmarkStatus('none');
    // _setBenchmarkInfo(null);
  }, [_automation]);

  /* Define filtered events */
  useEffect(() => {
    const _filteredEvents = _events.filter((event) => {
      let _includeObjectiveEvent = true;
      if (_isOnlyShowingObjectiveEvents) {
        _includeObjectiveEvent = Boolean(event.successful);
      }
      return (
        _includeObjectiveEvent && _observingMatchIds.includes(event.match._id)
      );
    });

    setVisibleEvents(_filteredEvents);
  }, [_events, _isOnlyShowingObjectiveEvents, _observingMatchIds]);

  /* Set history on automation load */
  useEffect(() => {
    const _firstPlayer = _automation?.players?.[0];
    let _playerHistory: FIAutomationPlayerMatch[] = [];
    if (_firstPlayer) {
      _playerHistory = getLastMatches(
        _firstPlayer.history,
        _observingMatchIds,
        true,
      ).sort(
        (a, b) =>
          new Date(b.match.date).getTime() - new Date(a.match.date).getTime(),
      );
    }
    _setHistory(_playerHistory);
  }, [_automation, _observingMatchIds]);

  /* Set observing matches on _observingMatchIds change */
  useEffect(() => {
    const _newObservingMatches = _allMatches.filter((match) =>
      _observingMatchIds.includes(match._id),
    );
    _setObservingMatches(_newObservingMatches);
  }, [_observingMatchIds, _allMatches]);

  /*
   * Handlers
   */
  // Calculate scores based on benchmark data, players and observing matches
  function _setScoresHandler(
    benchmarkAverage?: number,
    calculatePer90?: boolean,
    benchmarkedScore?: number,
  ) {
    if (!_automation?.players?.length || !_observingMatchIds?.length) {
      _setScores(null);
      _setBenchmarkedScore(undefined);
      return;
    }

    const _newBenchmarkedScore =
      typeof benchmarkedScore !== 'undefined'
        ? benchmarkedScore
        : _automation.benchmark_scores?.benchmarked_score;
    _setBenchmarkedScore(_newBenchmarkedScore);

    /* Filter history */
    const _filteredHistory = _automation.players[0].history.filter((history) =>
      _observingMatchIds.includes(history.match._id),
    );
    const _isRatioTracker =
      _automation.calculation === 'ratio' || _automation.calculation === 'rule';

    const _totalScores = generateTotalScoresForPlayer(
      _filteredHistory,
      !_automation.observing_players?.players?.length,
      _isRatioTracker,
      Boolean(_automation.metric),
      benchmarkAverage,
      _newBenchmarkedScore,
      calculatePer90,
    );

    const _shouldInvert = shouldInvertScore(_automation);
    _setTotalScores(_totalScores);
    _setScores(
      formatPlayerScores(
        _automation.action,
        _totalScores,
        _automation.calculation,
        _automation.rule_condition?.action,
        true,
        calculatePer90,
        _shouldInvert,
        _automation.metric,
      ),
    );
  }

  // Fetch automation
  async function _getAutomation(
    automationId: string,
    teamId?: string,
    dateAndTimeFilters?: FIAutomationDataQueryParams,
    // filters?: FIAutomationDataQueryParams,
  ) {
    if (!teamId) {
      teamId = _teamsContext.ownTeam?._id;
    }
    if (automationId && _teamsContext.ownTeam && teamId) {
      if (_automationLoadingState !== LOADING_STATE.INITING) {
        _setAutomationLoadingState(LOADING_STATE.LOADING);
      }

      try {
        // Define default event params (team_id for opponent-automations)
        const _params: FIEventFilters = {
          ...(props.automationType === 'opponent-automation'
            ? {'team._id': [teamId]}
            : {}),
        };

        // Fetch automation
        const _fetchedAutomation = await getSingleAutomation(
          props.automationType,
          automationId,
          dateAndTimeFilters,
          _params,
        );

        if (_fetchedAutomation.players?.length) {
          // Define default match filters based on response
          const _allMatchIds = _fetchedAutomation.players[0].history
            .filter((historyItem) => historyItem.checked === true) // Only include items with `checked: true`
            .map((historyItem) => historyItem.match._id);

          const _allMatchesByTeam = _fetchedAutomation.players[0].history
            .map((historyItem) => historyItem.match)
            .sort(
              (a, b) => new Date(b.date).valueOf() - new Date(a.date).valueOf(),
            );
          _setAllMatches(_allMatchesByTeam);
          _setObservingMatchIds(_allMatchIds);
        }

        /* Benchmark */
        /* Set default benchmark score */
        _setBenchmarkedScore(
          _fetchedAutomation.benchmark_scores?.benchmarked_score,
        );
        _setBenchmarkAverage(
          _fetchedAutomation.benchmark_scores?.benchmark_average,
        );

        // Set filters
        _setBenchmarkAndDateFilters({
          own_data: {
            time: _fetchedAutomation.own_filters,
          },
          benchmark: _fetchedAutomation.benchmark_filters || {},
        });

        // Check if benchmark is disabled
        const _newBenchmarkDisabledReason =
          getBenchmarkDisabledReason(_fetchedAutomation);

        if (_newBenchmarkDisabledReason) {
          _setBenchmarkDisabledReason(_newBenchmarkDisabledReason);

          /* Set automation */

          _setAutomation(_fetchedAutomation);
          _setAutomationLoadingState(LOADING_STATE.SUCCESS);
          return;
        }

        /* Fetch benchmark */
        // const _playerFilters = getPositionFilterByObservingPlayers(
        //   _teamsContext.ownPlayers,
        //   _fetchedAutomation.observing_players,
        // );

        // const _benchmarkFilters: FIBenchmarkOptions = {
        //   ..._playerFilters,
        //   ...(_competitionsContext.domesticCompetition && {
        //     competition_id: [_competitionsContext.domesticCompetition._id],
        //   }),

        //   ..._fetchedAutomation.benchmark_filters,
        // };

        if (_fetchedAutomation.benchmark_data) {
          _setBenchmarkStatus('active');
          _setBenchmarkData(_fetchedAutomation.benchmark_data);
        }

        /* Set automation */

        _setAutomation(_fetchedAutomation);
        _setAutomationLoadingState(LOADING_STATE.SUCCESS);
      } catch (error) {
        // TODO handle error
        _setError(true);
        _setAutomationLoadingState(LOADING_STATE.ERROR);
        _setAutomation(null);
      }
    }
  }

  function _onObservingTeamIdChange(teamId: string) {
    _setObservingTeamId(teamId);
    if (!_params.id || !_benchmarkAndDateFilters) {
      return;
    }

    _getAutomation(_params.id, teamId, _benchmarkAndDateFilters);
  }

  async function _onOwnDataFiltersChange(filters: FIBenchmarkTimeOptions) {
    if (!_params.id || !_benchmarkAndDateFilters) {
      return;
    }

    const _filtersForRequest: FIAutomationDataQueryParams = {
      ..._benchmarkAndDateFilters,
      own_data: {
        time: filters,
      },
    };
    _setSearchParams(serializeParams(_filtersForRequest));
    _getAutomation(_params.id, _observingTeamId, _filtersForRequest);
  }

  async function _getAutomationEvents(
    automationId: string,
    filters?: FIAutomationOwnDataQueryParams,
  ) {
    /* Fetch events - To be removed when we add events to trackers response */
    if (_teamsContext.ownTeam && _observingTeamId) {
      _setIsFetchingEvents(true);
      const _eventParams: FIEventFilters = {
        'own_team._id': [_teamsContext.ownTeam._id],
        ...(props.automationType !== 'tracker'
          ? {'team._id': [_observingTeamId]}
          : {}),
      };

      const _dateFilters: FIAutomationDataQueryParams = {
        own_data: filters || {},
        benchmark: {},
      };

      await fetchEventsForAutomation(
        props.automationType,
        automationId,
        _eventParams,
        _dateFilters,
      )
        .then((events) => setEvents(events))
        .finally(() => _setIsFetchingEvents(false));
    }
  }

  async function _getAutomationBenchmarkData(
    benchmarkFilters: FIAutomationDataQueryParams,
  ) {
    _setBenchmarkError(null);
    _setBenchmarkStatus('loading');
    _setBenchmarkAndDateFilters(benchmarkFilters);
    if (_automation) {
      let _teamId: string | undefined = undefined;
      if (props.automationType === 'opponent-automation') {
        _teamId = _observingTeamId;
      }

      try {
        let _result = await benchmarkAutomation(
          props.automationType,
          _automation._id,
          benchmarkFilters,
          _teamId,
        );

        let waitTime = 1000; // Start with 1 second

        while (_result.in_progress) {
          // Wait for the current wait time
          await new Promise((resolve) => setTimeout(resolve, waitTime));

          // Call benchmarkAutomation again
          _result = await benchmarkAutomation(
            props.automationType,
            _automation._id,
            benchmarkFilters,
            _teamId,
          );

          // Increase the wait time (add 1 second each time)
          waitTime = Math.min(waitTime + 1000, 10000); // Cap at 10 seconds to prevent excessively long waits
        }

        if (_result.ranking?.length > 1) {
          _setBenchmarkData(_result);
          _setBenchmarkStatus('active');

          _setBenchmarkAverage(_result.benchmark_average);
          _setScoresHandler(
            _result.benchmark_average,
            benchmarkFilters.benchmark.calculation === 'per_90',
            _result.benchmarked_score,
          );
        } else {
          _setBenchmarkData(undefined);
          _setBenchmarkStatus('error');
          // _setBenchmarkInfo(
          //   filters: benchmarkFilters,
          //   state: 'error',
          //   benchmark_data: undefined,
          //   error: 'no-data',
          // });
          _setBenchmarkedScore(undefined);
        }

        // _analyticsContext.trackEvent(ANALYTICS_EVENT.BENCHMARKED_TRACKER, {
        //   ...benchmarkFilters,
        //   id: _automation._id,
        // });
      } catch (error) {
        _setBenchmarkData(undefined);
        _setBenchmarkStatus('error');
        _setBenchmarkError(error ? (error as any).message : 'default');
      }
    }
  }

  async function _getBenchmarkHandler(benchmarkOptions: FIBenchmarkOptions) {
    const _filters: FIAutomationDataQueryParams = {
      benchmark: benchmarkOptions,
      own_data: _benchmarkAndDateFilters?.own_data || {time: undefined},
    };

    _getAutomationBenchmarkData(_filters);

    const _serializeduery = serializeParams(_filters);
    _setSearchParams(_serializeduery);
  }

  function _updateObservingPlayerInBenchmark() {
    if (_automation?.players && _teamsContext.ownTeam) {
      const _benchmarkItem: FIBenchmarkRankingItem = {
        team: _teamsContext.ownTeam,
        total_events: _totalScores?.total_events ?? 0,
        total_successful_events: _totalScores?.total_successful_events ?? 0,
        matches_played: _totalScores?.matches_played ?? 0,
        minutes_played: _totalScores?.minutes_played ?? 0,
        average: _totalScores?.average ?? 0,
      };
      const _currentRanking = _benchmarkData?.ranking;

      if (_currentRanking) {
        _addItemToRanking(
          _automation.players[0]._id,
          _benchmarkItem,
          _currentRanking,
        );
      }
    }
  }

  function _addItemToRanking(
    id: string,
    item: FIBenchmarkRankingItem,
    ranking: FIBenchmarkRankingItem[],
  ) {
    const _existingIndex = ranking.findIndex((r) => r.player?._id === id);
    if (_existingIndex !== -1) {
      // Replace the scores
      ranking[_existingIndex].total_events = item.total_events;
      ranking[_existingIndex].total_successful_events =
        item.total_successful_events;
      ranking[_existingIndex].matches_played = item.matches_played;
      ranking[_existingIndex].minutes_played = item.minutes_played;
      ranking[_existingIndex].average = item.average;
      _resortBenchmarkRanking(id, ranking);
    }
  }

  function _resortBenchmarkRanking(
    id: string,
    ranking: FIBenchmarkRankingItem[],
  ) {
    let _invertScores = false;
    if (_automation) _invertScores = shouldInvertScore(_automation);

    ranking = ranking
      .sort((a, b) => {
        const aScore = a?.average ?? 0;
        const bScore = b?.average ?? 0;

        // If _invertScores is true, sort in the opposite order
        return _invertScores ? aScore - bScore : bScore - aScore;
      })
      .map((item, index) => {
        return {
          ...item,
          benchmarked_score: getPercentileScore(ranking.length, index + 1),
        };
      });
    _updateIdToFindInRanking(id, ranking);
  }

  function _updateIdToFindInRanking(
    id: string,
    ranking: FIBenchmarkRankingItem[],
  ) {
    const _observingIndex = ranking.findIndex((r) => r.player?._id === id);
    if (_benchmarkData) {
      _benchmarkData.position_in_rank = _observingIndex;
      _benchmarkData.ranking = ranking;
    }
  }

  /* Match filter */
  function _onObservingMatchIdsChanged(selectedMatchIds: string[]) {
    _setObservingMatchIds(selectedMatchIds);
  }

  /* Video Player */
  function _openVideoPlayer(
    clips: FIMatchEvent[],
    selectedClip?: FIMatchEvent,
  ) {
    const _matchIdsInClips = clips.map((clip) => clip.match._id);
    const _uniqueMatchIdsInClips = Array.from(new Set(_matchIdsInClips));

    const _includedMatches = _allMatches.filter((match) =>
      _uniqueMatchIdsInClips.includes(match._id),
    );

    if (!_automation) return;

    _videoContext.openVideoPlayer(
      clips,
      _includedMatches,
      _automation.calculation === 'ratio' || _automation.calculation === 'rule',
      undefined,
      {
        action: _automation.rule_condition?.action || _automation.action,
        details: _automation.details || _automation.success_details,
        metric: _automation.metric,
      },
      selectedClip,
    );
  }

  /*
   * RETURN PROVIDER
   */
  return createProvider<AutomationDetailAPI, IProviderProps>(context, props, {
    automation: _automation,
    onAutomationChanged: _setAutomation,
    automationType: props.automationType,
    automationLoadingState: _automationLoadingState,
    isFetchingEvents: _isFetchingEvents,
    events: _events,
    visibleEvents: _visibleEvents,
    history: _history,
    scores: _scores,

    observingMatchIds: _observingMatchIds,
    observingMatches: _observingMatches,
    onObservingMatchIdChange: _onObservingMatchIdsChanged,

    observingTeamId: _observingTeamId,
    onObservingTeamIdChange: _onObservingTeamIdChange,
    onOwnDataFiltersChange: _onOwnDataFiltersChange,

    zones: _zones,

    benchmarkAndDateFilters: _benchmarkAndDateFilters,
    benchmarkStatus: _benchmarkStatus,
    benchmarkData: _benchmarkData,
    benchmarkError: _benchmarkError,

    getBenchmarkInfo: _getBenchmarkHandler,
    benchmarkDisabledReason: _benchmarkDisabledReason,

    benchmarkedScore: _benchmarkedScore,
    benchmarkAverage: _benchmarkAverage,
    error: _error,
    allMatches: _allMatches,

    getAutomation: _getAutomation,
    getAutomationEvents: _getAutomationEvents,

    isOnlyShowingObjectiveEvents: _isOnlyShowingObjectiveEvents,
    setIsOnlyShowingObjectiveEvents: _setIsOnlyShowingObjectiveEvents,

    openVideoPlayer: _openVideoPlayer,
  });
}
