import {FIPlayerWithPerformance} from '@my-game-plan/types';
import React, {useEffect} from 'react';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import {useTranslation} from 'react-i18next';
import Indicator from '../../dashboard/Indicator/Indicator';
import {Link, useLocation} from 'react-router-dom';
import {ROUTE} from '@/config/routes.config';
import {capitalize} from 'lodash';
import Logo from '../../common/Logo/Logo';
import IndicatorScoreNumber from '../../dashboard/Indicator/indicator-score-number.view';

interface IPlayerPerformanceCardProps {
  isTeamCard?: boolean;
  player: FIPlayerWithPerformance;
}

function PlayerPerformanceCard(
  props: IPlayerPerformanceCardProps,
): JSX.Element {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const _location = useLocation();
  const [_link, _setLink] = React.useState<string>('');

  useEffect(() => {
    let _baseURL = `/${ROUTE.players}/${props.player._id}`;
    if (props.isTeamCard) {
      _baseURL = `/${ROUTE.teams}`;
    }

    _setLink(`${_baseURL}${_location.search}`);
  }, [_location, props.isTeamCard, props.player]);

  /*
   * Render
   */
  let _Content = null;
  if (props.player.automations_count) {
    _Content = (
      <Grid container spacing={2} width="100%">
        {props.player.categories.map((category) => {
          return (
            <Grid item xs={12} lg={6} key={category.name}>
              <Typography fontSize={14} color="text.secondary">
                {capitalize(category.name) || t('players.performance.other')}
              </Typography>
              <Indicator value={category.benchmarked_score} hideValue />
            </Grid>
          );
        })}
      </Grid>
    );
  } else {
    _Content = (
      <Box
        display="flex"
        width="100%"
        height="100%"
        alignItems="center"
        justifyContent="center">
        <Typography color="secondary" fontSize={14}>
          {t('players.performance.noData.short')}
        </Typography>
      </Box>
    );
  }

  let _LinkButton = null;
  if (props.isTeamCard) {
    _LinkButton = (
      <Button component={Link} to={_link}>
        {t('team.cta.viewTeam')}
      </Button>
    );
  } else {
    _LinkButton = (
      <Button component={Link} to={_link}>
        {t('players.cta.viewPlayer')}
      </Button>
    );
  }
  return (
    <Card sx={{height: '100%', display: 'flex', flexDirection: 'column'}}>
      <CardHeader
        sx={{
          '& .MuiCardHeader-action': {alignSelf: 'center', mr: 0},
        }}
        title={
          props.isTeamCard
            ? t('eventAutomations.filter.entireTeam')
            : props.player.name
        }
        avatar={
          props.isTeamCard ? (
            <Logo src={props.player.image_url} size="large" />
          ) : (
            <Avatar
              src={props.player.image_url}
              sx={{
                borderStyle: 'solid',
                bgcolor: 'background.default',
                color: 'secondary.dark',
              }}
            />
          )
        }
        subheader={`${props.player.automations_count} ${t(
          'eventAutomations.trackersShort',
          {
            count: props.player.automations_count,
          },
        )}`}
        action={<IndicatorScoreNumber value={props.player.benchmarked_score} />}
      />
      <CardContent sx={{flexGrow: 1}}>{_Content}</CardContent>
      <CardActions sx={{justifyContent: 'flex-end'}}>{_LinkButton}</CardActions>
    </Card>
  );
}

export default PlayerPerformanceCard;
