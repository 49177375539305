import {useTeams} from '@/context/team.context';
import {FITeam} from '@my-game-plan/types';
import React, {useEffect, useState} from 'react';
import HeaderFilterButton from '../header-filter-button.view';

import ShieldIcon from '@mui/icons-material/Shield';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {useTranslation} from 'react-i18next';

interface IOpponentFilterButtonProps {
  selectedOpponentId: string | null;
  onOpponentSelect: (opponentId: string) => void;
}

function OpponentFilterButton(props: IOpponentFilterButtonProps): JSX.Element {
  /*
   * Hooks n State
   */

  const {t} = useTranslation();
  const [_anchorEl, _setAnchorEl] = useState<null | HTMLElement>(null);
  const [_opponentTeam, _setOpponentTeam] = useState<FITeam | null>(null);
  const _teamsContext = useTeams();

  /*
   * Side effects
   */
  useEffect(() => {
    const _matchingOpponent = _teamsContext.all.find(
      (team) => team._id === props.selectedOpponentId,
    );
    if (_matchingOpponent) {
      _setOpponentTeam(_matchingOpponent);
    }
  }, [props.selectedOpponentId, _teamsContext.all]);

  /*
   * Handlers
   */
  function _onMenuOpen(event: React.MouseEvent<HTMLElement>) {
    _setAnchorEl(event.currentTarget);
  }

  function _onMenuClose() {
    _setAnchorEl(null);
  }

  function _onOpponentChange(teamId: string) {
    //
    _onMenuClose();
    props.onOpponentSelect(teamId);
  }
  /*
   * Render
   */
  return (
    <HeaderFilterButton
      onPopupOpen={_onMenuOpen}
      ButtonIcon={ShieldIcon}
      buttonPrefix={t('eventAutomations.filter.observing.observing')}
      buttonValues={[
        [
          {type: 'value', value: _opponentTeam?.name || ''},
          {type: 'logo', value: _opponentTeam?.image_url || ''},
        ],
      ]}
      PopupContent={
        <Menu
          open={Boolean(_anchorEl)}
          anchorEl={_anchorEl}
          onClose={_onMenuClose}>
          {_teamsContext.opponentsOptions.map((opponent) => {
            return (
              <MenuItem
                key={opponent.value}
                selected={opponent.value === props.selectedOpponentId}
                onClick={() => _onOpponentChange(opponent.value)}>
                {opponent.label}
              </MenuItem>
            );
          })}
        </Menu>
      }
    />
  );
}

export default OpponentFilterButton;
