import React, {useEffect} from 'react';
import {
  createContextHook,
  createCustomContext,
} from '@/helpers/general/context_generators.helper';
import {LOADING_STATE, TOverviewScreenViewType} from '@/types/screen.types';
import {
  ENTIRE_TEAM_LABEL,
  FIAutomationDataQueryParams,
  FIBenchmarkOptions,
  FIBenchmarkTimeOptions,
  FIEventFilters,
  TEventAutomationType,
} from '@my-game-plan/types';
import {PropsWithChildren, useState} from 'react';
import {IOverviewScreenView} from '@/components/overview-screen/overview-screen.view';
import {useSearchParams} from 'react-router-dom';
import {formatSearchParamsToQueryParams} from '@/helpers/automation/automation.helper';

import {serializeParams} from '@/helpers/api.helper';
import {useTrackers} from './event-automations/tracker.context';
import {useTeams} from './team.context';

export interface IOverviewScreenAPI {
  automationType: TEventAutomationType;
  currentViewType: TOverviewScreenViewType;
  currentView: IOverviewScreenView | null;
  onCurrentViewChange: (viewType: TOverviewScreenViewType) => void;
  views: IOverviewScreenView[];

  benchmarkHandler: (props: FIBenchmarkOptions) => Promise<void>;
  timeFilterChangeHandler: (
    timeFilters: FIBenchmarkTimeOptions,
  ) => Promise<void>;
  timeAndBenchmarkFilters: FIAutomationDataQueryParams | null;

  selectedPlayerIds: string[];
  onPlayerSelect(playerId: string): void;

  selectedOpponent: string | null;
  onOpponentSelect(opponentId: string): void;
}

export interface IOverviewScreenProps {
  automationType: TEventAutomationType;
  views: IOverviewScreenView[];
  filters: FIAutomationDataQueryParams | null;
  onFiltersChange: (filters: FIAutomationDataQueryParams | null) => void;
}

const context = createCustomContext<IOverviewScreenAPI>();
export const useOverviewScreen = createContextHook(context);

export function OverviewScreenProvider(
  props: PropsWithChildren<IOverviewScreenProps>,
): JSX.Element {
  /*
   * Hooks n State
   */
  const _teamsContext = useTeams();
  const _trackerContext = useTrackers();
  const [_currentViewType, _setCurrentViewType] =
    useState<TOverviewScreenViewType>(
      props.automationType === 'opponent-automation' ? 'table' : 'cards',
    );
  const [_currentView, _setCurrentView] = useState<IOverviewScreenView | null>(
    null,
  );
  const [_selectedPlayerIds, _setSelectedPlayerIds] = useState<string[]>([]);
  const [_selectedOpponentId, _setSelectedOpponentId] = useState<string | null>(
    null,
  );

  // Filters
  const [_searchParams, _setSearchParams] = useSearchParams();

  /*
   * Side effects
   */
  /* Set current view when _currentViewType changed */
  useEffect(() => {
    const _matchingView = props.views.find(
      (view) => view.type === _currentViewType,
    );
    _setCurrentView(_matchingView || null);
  }, [_currentViewType, props.views]);

  // Load data on init
  useEffect(() => {
    if (!_currentView || _currentView.loadingState !== LOADING_STATE.INITING) {
      return;
    }

    let _initialFilters: FIAutomationDataQueryParams | undefined = undefined;

    if (_searchParams.toString()) {
      // If searchparams are passed, set defaults
      _initialFilters = formatSearchParamsToQueryParams(_searchParams);
    }

    _currentView.getData(_initialFilters);
  }, [_searchParams, _currentView?.type]);

  // HACK- Set default selected players based on trackers
  useEffect(() => {
    if (props.automationType !== 'tracker') {
      return;
    }

    const _playersWithTrackers = _trackerContext.players.filter(
      (p) => p.automations_count && p.display_name !== ENTIRE_TEAM_LABEL,
    );

    const _playerIds = _playersWithTrackers.map((p) => p._id);

    const _entireTeam = _trackerContext.players.find(
      (p) => p.display_name === ENTIRE_TEAM_LABEL,
    );
    if (_entireTeam?.automations_count) {
      _playerIds.push(ENTIRE_TEAM_LABEL);
    }
    _setSelectedPlayerIds(_playerIds);
  }, [props.automationType, _trackerContext.players]);

  // HACK - Set default next opponent
  useEffect(() => {
    _setSelectedOpponentId(_teamsContext.nextOpponentId);
  }, [_teamsContext.nextOpponentId]);

  /*
   * Handlers
   */
  async function _benchmarkHandler(
    benchmarkOptions: FIBenchmarkOptions,
  ): Promise<void> {
    if (!props.filters || !_currentView) {
      return;
    }

    // Update filters state
    const _filters: FIAutomationDataQueryParams = {
      ...props.filters,
      benchmark: benchmarkOptions,
    };
    props.onFiltersChange(_filters);

    // Set url
    const _serializedParams = serializeParams(_filters);
    _setSearchParams(new URLSearchParams(_serializedParams));

    // Fetch data
    _currentView.getData(_filters);
  }

  async function _timeFilterChangeHandler(
    timeFilters: FIBenchmarkTimeOptions,
  ): Promise<void> {
    if (!props.filters || !_currentView) {
      return;
    }

    // Update filters state
    const _filters: FIAutomationDataQueryParams = {
      ...props.filters,
      own_data: {
        time: timeFilters,
      },
    };
    props.onFiltersChange(_filters);

    // Set url
    const _serializedParams = serializeParams(_filters);
    _setSearchParams(new URLSearchParams(_serializedParams));

    // Fetch data
    _currentView.getData(_filters);
  }

  function _onPlayerSelect(playerId: string) {
    //
    if (_selectedPlayerIds.includes(playerId)) {
      const _filteredPlayerIds = _selectedPlayerIds.filter(
        (id) => id !== playerId,
      );
      _setSelectedPlayerIds(_filteredPlayerIds);
    } else {
      _setSelectedPlayerIds([..._selectedPlayerIds, playerId]);
    }
  }

  function _onOpponentSelect(opponentId: string) {
    _setSelectedOpponentId(opponentId);
    if (!_currentView) {
      return;
    }

    const _eventFilters: FIEventFilters = {
      'team._id': [opponentId],
    };
    _currentView.getData(props.filters || undefined, _eventFilters);
  }

  /*
   * Return context
   */
  return (
    <context.Provider
      value={{
        automationType: props.automationType,
        currentViewType: _currentViewType,
        currentView: _currentView,
        views: props.views,
        onCurrentViewChange: _setCurrentViewType,
        benchmarkHandler: _benchmarkHandler,
        timeFilterChangeHandler: _timeFilterChangeHandler,
        timeAndBenchmarkFilters: props.filters,

        selectedPlayerIds: _selectedPlayerIds,
        onPlayerSelect: _onPlayerSelect,

        selectedOpponent: _selectedOpponentId,
        onOpponentSelect: _onOpponentSelect,
      }}>
      {props.children}
    </context.Provider>
  );
}
