import Card from '@mui/material/Card';

import CircularProgress from '@mui/material/CircularProgress';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import BenchmarkTable from '../benchmark-table/benchmark-table.view';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import {useConfirm} from 'material-ui-confirm';
import {DEFAULT_MODAL_CONFIG} from '@/config/modal.config';

import {SxProps, Theme} from '@mui/material';

import CustomEmptyState from '@/components/error-states/CustomEmptyState';

import {useAutomationDetail} from '@/context/event-automations/automation-detail.context';
import BenchmarkFilterPopup from '../benchmark-filter/benchmark-filter-popup.view';

const VALUE_STYLES: SxProps<Theme> = {
  fontSize: 24,
  fontWeight: 'bold',
  lineHeight: 1,
};

function TrackerBenchmarkCard(): JSX.Element | null {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const _confirm = useConfirm();
  const _automationDetailContext = useAutomationDetail();

  const [_isTeamTracker, _setIsTeamTracker] = useState<boolean>(false);
  const [_popupAnchorEl, _setPopupAnchorEl] = useState<HTMLElement | null>(
    null,
  );

  /* Set Team/Player info to display in card */
  useEffect(() => {
    const _isTeamTrackerCheck =
      !_automationDetailContext.automation?.observing_players?.players?.length;
    _setIsTeamTracker(_isTeamTrackerCheck);
  }, [_automationDetailContext.automation]);

  /*
   * Handlers
   */
  function _onViewRankingClick() {
    if (_automationDetailContext.benchmarkData) {
      _confirm({
        ...DEFAULT_MODAL_CONFIG,
        dialogProps: {
          maxWidth: 'md',
        },
        title: t('eventAutomations.benchmark.ranking.title'),
        content: (
          <BenchmarkTable
            ranking={_automationDetailContext.benchmarkData.ranking}
            isTeamTracker={_isTeamTracker}
            positionInRank={
              _automationDetailContext.benchmarkData.position_in_rank
            }
            perNinety={
              _automationDetailContext.benchmarkAndDateFilters?.benchmark
                ?.calculation === 'per_90'
            }
            isRatio={
              _automationDetailContext.automation?.calculation === 'ratio' ||
              _automationDetailContext.automation?.calculation === 'rule'
            }
          />
        ),
      });
    }
  }

  function _onEditFiltersClick(event: React.MouseEvent<HTMLButtonElement>) {
    _setPopupAnchorEl(event.currentTarget);
  }

  function _onPopupClose() {
    _setPopupAnchorEl(null);
  }

  /*
   * Render
   */
  if (
    _automationDetailContext.benchmarkStatus === 'none' ||
    _automationDetailContext.benchmarkDisabledReason
  )
    return null;
  const _isRatio =
    _automationDetailContext.automation?.calculation === 'ratio' ||
    _automationDetailContext.automation?.calculation === 'rule';
  let _Content = (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        height: '100%',
        minHeight: 320,
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      <CircularProgress color="primary" />
    </Box>
  );

  if (_automationDetailContext.benchmarkStatus === 'error') {
    // _Content = <Typography>{t('eventAutomations.benchmark.error')}</Typography>;
    _Content = (
      <Box p={2} minHeight={320}>
        <CustomEmptyState
          size="small"
          header={t([
            `eventAutomations.benchmark.error.${_automationDetailContext.benchmarkError}.title`,
            'eventAutomations.benchmark.error.default.title',
          ])}
          description={t([
            `eventAutomations.benchmark.error.${_automationDetailContext.benchmarkError}.description`,
            'eventAutomations.benchmark.error.default.description',
          ])}
          primaryAction={
            <Button
              size="small"
              variant="outlined"
              onClick={_onEditFiltersClick}>
              {t('eventAutomations.benchmark.update')}
            </Button>
          }
        />
      </Box>
    );
  } else if (
    _automationDetailContext.automation &&
    _automationDetailContext.benchmarkStatus === 'active' &&
    _automationDetailContext.benchmarkData
  ) {
    const {total_players, position_in_rank, ranking} =
      _automationDetailContext.benchmarkData;

    // Ranking
    let _RankingPosition = (
      <Box display="flex" flexDirection="row" alignItems="flex-end" flex={1}>
        <Typography sx={VALUE_STYLES}>{position_in_rank + 1}</Typography>
        <Typography sx={{lineHeight: 1.3}}>{`/${total_players}`}</Typography>
      </Box>
    );

    if (position_in_rank === -1) {
      const _prefix = t(
        `eventAutomations.benchmark.ranking.${
          _isTeamTracker ? 'team' : 'player'
        }`,
      );
      const _label = `${_prefix} ${t(
        'eventAutomations.benchmark.ranking.notInRanking',
      )}`;
      _RankingPosition = (
        <Typography flex={1} mt={0.5} color="secondary">
          {_label}
        </Typography>
      );
    }

    _Content = (
      <Stack gap={1}>
        {/* Head */}
        <Stack gap={0.5} px={2}>
          <Typography variant="caption">
            {t('eventAutomations.benchmark.ranking.title')}
          </Typography>
          <Box display="flex" flexDirection="row" alignItems="center">
            {_RankingPosition}

            <Button
              color="secondary"
              size="small"
              variant="outlined"
              endIcon={<ArrowForwardIcon />}
              onClick={_onViewRankingClick}>
              {t('eventAutomations.benchmark.ranking.fullRankingCta')}
            </Button>
          </Box>
        </Stack>

        <Divider />
        <Box>
          <BenchmarkTable
            ranking={ranking}
            compact
            isTeamTracker={_isTeamTracker}
            positionInRank={position_in_rank}
            isRatio={_isRatio}
          />
        </Box>
      </Stack>
    );
  }

  return (
    <Card sx={{py: 2, width: 280}}>
      {_Content}
      <BenchmarkFilterPopup
        anchorEl={_popupAnchorEl}
        onClose={_onPopupClose}
        onBenchmarkSubmit={_automationDetailContext.getBenchmarkInfo}
        benchmarkOptions={
          _automationDetailContext.benchmarkAndDateFilters?.benchmark
        }
        isRatioOrRule={_isRatio}
        isObservingPlayer={!_isTeamTracker}
      />
    </Card>
  );
}

export default TrackerBenchmarkCard;
